import React from 'react'
import { createStyles, Theme } from '@rsmus/react-ui'
import { createUseStyles } from 'react-jss'

const styles = ({ palette }: Theme) =>
  createStyles({
    background: {
      position: 'fixed',
      display: 'flex',
      justifyContent: 'center',
      zIndex: 10000,
      paddingTop: '100px',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      overflow: 'auto',
      backgroundColor: palette.common.black,
      opacity: 0.4,
    },
    container: {
      display: 'flex',
      justifyContent: 'center',
    },
    modal: {
      backgroundColor: palette.common.white,
      position: 'absolute',
      borderRadius: '15px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: 'auto',
      padding: '24px',
      border: '1px solid #888',
      width: '85%',
      maxWidth: '600px',
      textAlign: 'center',
      justifyContent: 'space-between',
      opacity: 1.0,
      zIndex: 10001,
      top: '35%',
      left: 0,
      right: 0,
    },
    dialog: {
      fontSize: '28.8px',
      lineHeight: 1.4,
      maxWidth: '90%',
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      color: palette.common.black,
    },
  })

export interface ModalProps {
  children: React.ReactNode
  shown: boolean
}

type StyleReturn = keyof ReturnType<typeof styles>
const useStyles = createUseStyles<Theme, StyleReturn>(styles)

const ComponentContent = ({ children, shown }: ModalProps) => {
  const classes = useStyles()
  if (shown) {
    return (
      <div className={classes.container}>
        <div className={classes.background} />
        <div className={classes.modal}>
          <div className={classes.dialog}>{children}</div>
        </div>
      </div>
    )
  }
  return null
}

export const RedirectModal = ComponentContent
