import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { createUseStyles } from 'react-jss'
import {
  createStyles,
  Formik,
  FormikHelpers,
  FormikProps,
  TextField,
  Theme,
} from '@rsmus/react-ui'
import { ProvisionUserRequestModel, UserProfileModel } from '../../models'
import { AppState } from '../../redux'
import { EditModal } from '../Shared/EditModal'
import * as yup from 'yup'
import { useUsers } from '../../hooks/useUsers'
import { debounce } from 'debounce'
const styles = ({ palette }: Theme) =>
  createStyles({
    root: {
      fontSize: '1rem',
    },
    modalButton: {
      backgroundColor: palette.blue.main,
      color: palette.common.white,
      margin: '2rem',
      marginBottom: '.1rem',
      width: '6rem',
      display: 'inline-flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalTitle: {
      paddingBottom: '1.3rem',
      textAlign: 'left',
      fontSize: '2.2rem',
      fontWeight: 'bold',
    },
    confirmMessage: {
      color: `${palette.green.dark}`,
      zIndex: 1,
      padding: '0.5rem',
      minWidth: '8rem',
      border: 0,
      borderRadius: '0.25rem',
      marginRight: '1rem',
    },
    checkIcon: {
      float: 'left',
      marginTop: '1.2rem',
    },
    successText: {
      float: 'left',
      marginLeft: '.5rem',
      marginTop: '1rem',
    },
    field: {
      paddingBottom: '.75rem',
      textAlign: 'left',
    },
    error: {
      color: palette.informational.red,
    },
    errorBorder: {
      border: palette.informational.red,
    },
    label: {
      textAlign: 'left',
    },
  })

type StyleReturn = keyof ReturnType<typeof styles>
const useStyles = createUseStyles<Theme, StyleReturn>(styles)

export interface CreateUserModalProps {
  onClose: () => void
  onAddUser: (user: UserProfileModel) => Promise<void>
}

export const CreateUserModal = ({ onClose ,onAddUser}: CreateUserModalProps) => {
  const classes = useStyles()
  const [submitting, setSubmitting] = useState(false)

  const clientId = useSelector((state: AppState) => state.clientUsers.clientId) // needed for the save.
  const {
    userResult,
    getUserByEmail,
    
    requestErrors,
    requestStatus,
  } = useUsers()
  const [disabled, setDisabled] = useState(false)
  const [localUser, setLocalUser] = useState<UserProfileModel>()

  const close = () => {
    onClose()
  }
  const debouncedChangeQuery = useCallback(
    debounce((email: string) => getUserByEmail(email), 500),
    []
  )
  const handleAddUser = async (user: UserProfileModel) => {
    // const model: ProvisionUserRequestModel = {
    //   organizationId: clientId,
    //   email: user.email,
    //   firstName: user.firstName,
    //   lastName: user.lastName,
    //   title: user.title,
    //   claims: ['ClientUser'],
    // }
    await onAddUser(user)
  }

  const onSubmit: any = async (
    values: UserProfileModel,
    formikHelpers: FormikHelpers<UserProfileModel>
  ) => {
    try {
      setSubmitting(true)
      formikHelpers.setSubmitting(true)
      /// we need the save here
      await handleAddUser(values)
    } catch (e) {
      alert(e)
    } finally {
      // setSubmitting(false)
      // formikHelpers.setSubmitting(false)
      // // no errors.
      // if (Object.keys(requestErrors).length === 0) {
      //     formikHelpers.resetForm()
      //     close()
      // }
    }
  }

  const isEmail = (value: string) =>
    /\s*[^\s@]+@[^\s@]+\.[^\s@]+\s*/.test(value)
  const isEmailFormatted = (value: string) => /^\S+$/g.test(value.trim())
  const emailExists = (value: string) => {
    return (
      localUser?.email.toLocaleLowerCase() !== value.trim().toLocaleLowerCase()
    )
  }
  const getValidationSchema = () => {
    return yup.object().shape({
      email: yup
        .string()
        .test('email', 'Email address is not valid or Exists', (value) => {
          const isValid =
            !value ||
            (isEmailFormatted(value) && isEmail(value) && emailExists(value))
          return isValid
        })
        .required('Required'),
      firstName: yup
        .string()
        .min(1, 'Too Short!')
        .max(50, 'Too Long, Max 50 Characters.')
        .required('Required'),
      lastName: yup
        .string()
        .min(1, 'Too Short!')
        .max(50, 'Too Long, Max 50 Characters.')
        .required('Required'),
      title: yup
        .string()
        .min(1, 'Too Short!')
        .max(100, 'Too Long, Max 100 Characters.')
        .nullable(),
    })
  }
  let formprops: FormikProps<UserProfileModel>
  // we need to check if hte user exsists and disable the save

  const handleOnChangeEmail = (
    e: { target: { value: string } },
    props: FormikProps<UserProfileModel>
  ) => {
    formprops = props //  we need this to do some validation setting later not sure we need to do this

    if (
      e.target.value &&
      isEmailFormatted(e.target.value) &&
      isEmail(e.target.value)
    ) {
      debouncedChangeQuery(e.target.value)
    }
    props.handleChange(e)
    // we need to compare value here to set if changed enable other fields.
    if (
      !(
        localUser &&
        localUser.email.toLocaleLowerCase() ===
          e.target.value.trim().toLocaleLowerCase()
      )
    ) {
      setDisabled(false)
    }
  }
  useEffect(() => {
    if (userResult?.id && formprops) {
      formprops.values.firstName = userResult.firstName
      formprops.values.lastName = userResult.lastName
      formprops.values.title = userResult.title
      formprops.setFieldError('email', 'User exists. Cannot create')
      setDisabled(true)
      setLocalUser(userResult)
    }
    // eslint-disable-next-line
  }, [userResult])

  const errMessages = () => {
    let messages = new Array<string>()
    for (let n in requestErrors) {
      messages.push(requestErrors[n])
    }
    // a single comma seperated error string returned.
    return messages.join(',')
  }

  useEffect(() => {
    // no errors.
    if (requestStatus === 'Success') {
      setSubmitting(false)
      formprops.setSubmitting(false)
      formprops.resetForm()
      close()
    }
    if (requestStatus === 'Failed') {
      setSubmitting(false)
      formprops.setSubmitting(false)
      formprops.setFieldError('email', errMessages())
    }
    // eslint-disable-next-line
  }, [requestStatus])

  const userInitlaValues: UserProfileModel = {
    firstName: '',
    lastName: '',
    title: '',
    email: '',
    id: '',
    status: '',
    rmsIsInternal: false,
    rsmUid: '',
    rsmIsFederated: false
  }

  return (
    // <div>
    //   <div>
    //     {isUserErrModalOpen && (
    //       <UserErrModal
    //         handleModalClose={handleUserErrModalClose}
    //         userErrs={requestErrors}
    //       />
    //     )}
    //   </div>

    <Formik
      enableReinitialize={true}
      validateOnMount={false}
      validateOnChange={true}
      validateOnBlur={true}
      initialValues={userInitlaValues}
      validationSchema={getValidationSchema}
      onSubmit={onSubmit}
    >
      {(props) => {
        formprops = props
        return (
          <form onSubmit={props.handleSubmit}>
            <EditModal
              shown={true}
              loading={false}
              saving={submitting}
              onCancel={close}
              onSave={props.handleSubmit}
              disabledSave={disabled}
            >
              <div className={classes.root}>
                <div className={classes.modalTitle}> Add A New User </div>
                <div className={classes.field}>
                  <TextField
                    name='email'
                    labelText='Email'
                    handleChange={(e) => handleOnChangeEmail(e, props)}
                    error={
                      !!props.errors.email &&
                      (props.submitCount > 0 || props.touched.email)
                    }
                    errorText={props.errors.email}
                    disabled={submitting}
                  />
                </div>
                <div className={classes.field}>
                  <TextField
                    name='firstName'
                    labelText='First Name'
                    error={
                      !!props.errors.firstName &&
                      (props.submitCount > 0 || props.touched.firstName)
                    }
                    errorText={props.errors.firstName}
                    disabled={submitting || disabled}
                  />
                </div>
                <div className={classes.field}>
                  <TextField
                    name='lastName'
                    labelText='Last Name'
                    error={
                      !!props.errors.lastName &&
                      (props.submitCount > 0 || props.touched.lastName)
                    }
                    errorText={props.errors.lastName}
                    disabled={submitting || disabled}
                  />
                </div>
                <div className={classes.field}>
                  <TextField
                    name='title'
                    labelText='Title'
                    error={
                      !!props.errors.title &&
                      (props.submitCount > 0 || props.touched.title)
                    }
                    errorText={props.errors.title}
                    disabled={submitting || disabled}
                  />
                </div>
              </div>
            </EditModal>
          </form>
        )
      }}
    </Formik>
    // </div>
  )
}
