import { applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction'
import thunk, { ThunkAction, ThunkDispatch, ThunkMiddleware } from 'redux-thunk'
import { Actions } from './actions'
import {
  appConfigurationReducer as appConfiguration,
  clientTeamAssignmentsReducer as clientTeamAssignments,
  clientUserReducer as clientUser,
  clientUsersReducer as clientUsers,
  engagementsReducer as engagements,
  organizationsFilterReducer as organizationsFilter,
  taxFormsReducer as taxForms,
  userInfoReducer as userInfo,
  userPermissionsReducer as userPermissions,
  userManagementReducer as userManagement,
  validationReducer as validationErrors,
  AppConfigurationState,
  ClientTeamAssignmentsState,
  ClientUserState,
  ClientUsersState,
  EngagementsState,
  OrganizationsFilterState,
  TaxFormsState,
  UserInfoState,
  UserManagementState,
  ValidationState,
  UserPermissionsState,
 
} from './reducers'

export interface AppState {
  appConfiguration: AppConfigurationState
  clientTeamAssignments: ClientTeamAssignmentsState
  clientUser: ClientUserState
  clientUsers: ClientUsersState
  engagements: EngagementsState
  organizationsFilter: OrganizationsFilterState
  taxForms: TaxFormsState
  userInfo: UserInfoState
  userPermissions: UserPermissionsState
  userManagement: UserManagementState
  validationErrors: ValidationState
}

export type AppThunk<R = void> = ThunkAction<R, AppState, {}, Actions>

/**
 * Modified signature of the Redux _dispatch_ function after applying the redux-thunk middleware.
 */
export type AppDispatch = ThunkDispatch<AppState, {}, Actions>

// const composeEnhancers = composeWithDevTools({})
// const middleware = applyMiddleware<AppDispatch>(thunk)

const rootReducer = combineReducers<AppState, Actions>({
  appConfiguration,
  clientTeamAssignments,
  clientUser,
  clientUsers,
  engagements,
  organizationsFilter,
  taxForms,
  userInfo,
  userPermissions,
  userManagement,
  validationErrors,
})

// export const store = createStore(rootReducer, composeEnhancers(middleware))

const compose = composeWithDevTools({
  maxAge: 400,
  // actionsBlacklist: ['OPENER_ALLOW_ONLY_ONE', 'GET_BEGIN', 'GET_END'],
  predicate: (_, action) => !['RULE'].some(a => action.type.startsWith(a)),
  shouldRecordChanges: false,
})

const store = createStore(
  rootReducer,
  {},
  compose(
    process.env.NODE_ENV !== 'production' &&
      process.env.REACT_APP_ENABLE_REDUX_IMMUTABLE_STATE_INVARIANT === 'true'
      ? applyMiddleware(
          thunk as ThunkMiddleware<AppState, Actions>,
          require('redux-immutable-state-invariant').default()
        )
      : applyMiddleware(
          thunk as ThunkMiddleware<AppState, Actions>
        )
  )
)

export default store
