import { AppThunk } from './store'
import { Actions } from './actions'
import ky from '@rsmus/ky-auth'
import { ClientUser, TeamAssignment } from '../models'
import { buildApiUrl } from '../apiUrl'

let controller = new AbortController()

export const getById =
  (id: string): AppThunk =>
  async (dispatch) => {
    controller.abort()

    try {
      const { signal } = controller
      dispatch(Actions.getClientUsersBegin())

      const url = buildApiUrl(`clients/${id}/users`)

      const results = await ky
        .get(url, {
          signal,
          timeout: 30000,
        })
        .json<Array<ClientUser>>()

      dispatch(Actions.getClientUsersEnd(results))
      dispatch(Actions.setClientIdEnd(id))
    } catch (error) {
      dispatch(Actions.getClientUsersError(error))
      dispatch(Actions.setClientIdEnd(''))
    }
  }

export const clearUsers = (): AppThunk => (dispatch) => {
  dispatch(Actions.clearClientUsers())
}
export const SetUserStatus =
  (assignment: TeamAssignment): AppThunk =>
  (dispatch) => {
    const usercilent: ClientUser = {
      email: assignment.email,
      userId: assignment.userId,
      name: assignment.name + '',
      firstName: assignment.firstName,
      lastName: assignment.lastName,
      type: assignment.type,
      role: assignment.eidRole + '',
      roleDescription: assignment.eidRoleDescription + '',
      rsmIsFederated: assignment.rsmIsFederated,
      status: assignment.status + '',
      lastLogin: assignment.lastLogin + '',
    }
    dispatch(Actions.setUserStatus(usercilent))
  }
