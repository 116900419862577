import { PingIdentityContext } from '@rsmus/react-pingidentity'
import React, { ReactNode } from 'react'
import { useHasInternalClaim } from '../hooks/useHasInternalClaim'
import { NotAuthorized } from '../components/Authorization/NotAuthorized'

type IsAuthorizedProps = {
  children: ReactNode
}

export const Authorized = ({ children }: IsAuthorizedProps) => {
  const { authorized, isLoading } = useHasInternalClaim()
  const auth = React.useContext(PingIdentityContext)

  if (isLoading) {
    return null
  }

  if (!auth.user || !authorized) {
    return <NotAuthorized />
  }

  return <>{children}</>
}
