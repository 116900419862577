import React, { CSSProperties } from 'react'
import { createUseStyles } from 'react-jss'
import { createStyles, Theme, Button } from '@rsmus/react-ui'
import { Modal } from '../Shared/Modal'
import { Dictionary } from '../../models'
import { FaTimesCircle } from 'react-icons/fa'

const styles = ({ palette, typogrphaphy }: Theme) =>
  createStyles({
    modal: {
      z_Index: 9999,
    },
    failure: {
      width: '100%',
      fontSize: '300%',
      marginBottom: '10px',
      color: `${palette.informational.red}`,
    },
    modalText: {
      fontSize: '1.25rem',
    },
    modalList: {
      textAlign: 'initial',
      listStyleType: 'none', /* Remove bullets */
      // padding: 0, /* Remove padding */
      // margin: 0, /* Remove margins */
    },
    modalButton: {
      display: 'inline-block !important',
      backgroundColor: palette.blue.main,
      color: palette.common.white,
      minWidth: '100px',
    },
  })

type StyleReturn = keyof ReturnType<typeof styles>
const useStyles = createUseStyles<Theme, StyleReturn>(styles)

export interface UserErrModelProps {
  handleModalClose: () => void
  userErrs: Dictionary<string>
  titleErr:string
}
export const UserErrModal = ({
  handleModalClose,
  userErrs,
  titleErr,
}: UserErrModelProps) => {
  const classes = useStyles()
  // get The error messages
  const errMessages = () => {
    let messages = new Array<JSX.Element>()
    Object.keys(userErrs).forEach((k) => {
      messages.push(<li>{userErrs[k]}</li>)
    })
    return messages
  }
  const modalStyle = {
    zIndex: 999,
  } as CSSProperties

  return (
    <div style={modalStyle}>
    <Modal shown={true} onClose={handleModalClose}>
      <div className={classes.modalText}>
        <p>
          <FaTimesCircle className={classes.failure} />
          <span>{titleErr}</span>
        </p>
        <ul className={classes.modalList}>{userErrs && errMessages()}</ul>
      </div>
      <Button className={classes.modalButton} onClick={handleModalClose}>
        OK
      </Button>
    </Modal>
    </div>
  )
}
