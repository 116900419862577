import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import {
  createStyles,
  Theme,
  Grid,
  useFormikContext,
  FormikProps,
} from '@rsmus/react-ui'
import {
  TeamAssignment,
  UserProfileModel,
  ProvisionUserRequestModel,
} from '../../models'
import { AppState } from '../../redux'
import { FormModel } from '../EngagementScene/models'
import { getMergedClientUserAssignments } from '../EngagementScene/formHelpers'
import { FindUserButton } from '../AddNewUser/FindUserButton'
import { useUsers } from '../../hooks/useUsers'
import { UserErrModal } from '../AddNewUser/UserErrModal'
import { Loader } from '../Loader'
const roles = [
  { label: 'Primary Reviewer', value: 'PrimaryReviewer' },
  { label: 'Preparer', value: 'Preparer' },
  { label: 'Secondary Reviewer', value: 'SecondaryReviewer' },
  { label: 'Concurring Reviewer', value: 'ConcurringReviewer' },
  { label: 'Signer', value: 'Signer' },
  { label: 'Viewer', value: 'Viewer' },
] as const

const UserRow = ({
  rowNumber,
  rowData,
  setFieldValue,
}: {
  rowNumber: number
  rowData: TeamAssignment
  setFieldValue: (name: string, value: any) => void
}) => {
  useEffect(() => {
    const isAssigned = Object.values(rowData.roles).some((v) => v === true)
    if (rowData.isAssigned !== isAssigned) {
      setFieldValue(`rsmTeamAssignments[${rowNumber}].isAssigned`, isAssigned)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFieldValue, rowData.roles])

  return (
    <tr>
      <td>
        <input
          type='checkbox'
          name={`rsmTeamAssignments[${rowNumber}].isAssigned`}
          onChange={(e) => {
            const checked = e.currentTarget.checked
            setFieldValue(
              `rsmTeamAssignments[${rowNumber}].isAssigned`,
              checked
            )
            if (!checked) {
              setFieldValue(
                `rsmTeamAssignments[${rowNumber}].roles`,
                Object.keys(rowData.roles).reduce((acc: any, key) => {
                  return { ...acc, [key]: false }
                }, {})
              )
            }
          }}
          checked={!!rowData.isAssigned}
        />
      </td>
      <td>
        {`${rowData.lastName}, ${rowData.firstName}`}
        <br />
        <span>{rowData.userId}</span>
      </td>
      {roles.map(({ value }) => {
        return (
          <td key={value}>
            <input
              type='checkbox'
              name={`rsmTeamAssignments[${rowNumber}].roles.${value}`}
              onChange={(e) => {
                setFieldValue(
                  `rsmTeamAssignments[${rowNumber}].roles.${value}`,
                  e.currentTarget.checked
                )
              }}
              checked={!!rowData.roles[value]}
            />
          </td>
        )
      })}
    </tr>
  )
}

const styles = ({ palette, typogrphaphy }: Theme) =>
  createStyles({
    title: {
      ...typogrphaphy.bold,
      fontSize: '1.2rem',
      color: palette.grey[500],
    },
    tableWrapper: {
      width: '100%',
      margin: '1rem 0',
    },
    errorMessage: {
      color: palette.informational.red,
      margin: '1rem auto 0',
    },
    error: {
      border: `1px solid ${palette.informational.red}`,
    },
    plain: {
      border: '0px',
    },
    table: {
      width: '100%',
      '& thead': {
        '& th': {
          '&:first-child': {
            width: 50,
            textAlign: 'center',
          },
          '&:nth-child(2)': {
            textAlign: 'left',
            width: 300,
          },
          width: 100,
          textAlign: 'center',
          fontSize: '.8rem',
          wordWrap: 'normal',
          lineHeight: '.8rem',
          padding: '.5rem 0',
        },
      },
      '& tbody': {
        '& tr:nth-child(odd) td': {
          backgroundColor: palette.grey[100],
        },
        '& td': {
          verticalAlign: 'top',
          textAlign: 'center',
          padding: '5px 4px 4px',
          '&:nth-child(2)': {
            textAlign: 'left',
            paddingTop: 4,
          },
          '& span': {
            color: palette.grey[600],
          },
        },
      },
    },
    table2: {
      width: '100%',
      '& thead': {
        '& th': {
          '&:first-child': {
            width: 50,
            textAlign: 'center',
          },
          '&:nth-child(2)': {
            width: 300,
            textAlign: 'left',
          },
          fontSize: '.8rem',
          wordWrap: 'normal',
          lineHeight: '.8rem',
          padding: '.5rem 0',
        },
      },
      '& tbody': {
        '& tr:nth-child(odd) td': {
          backgroundColor: palette.grey[100],
        },
        '& td': {
          padding: '5px 4px 4px',
          '&:first-child': {
            textAlign: 'center',
            verticalAlign: 'top',
          },
          '&:nth-child(2)': {
            paddingTop: 4,
            verticalAlign: 'top',
          },
          '& span': {
            color: palette.grey[600],
          },
        },
      },
    },
    button: {
      zIndex: 1,
      padding: '0.5rem',
      minWidth: '8rem',
      border: 0,
      borderRadius: '0.25rem',
      marginRight: '1rem',
      marginLeft: '1rem',
    },
    '&:focus': {
      // TODO: Set a nicer style for focus outline
      outline: 'none',
    },
    buttonPrimary: {
      color: palette.common.white,
      backgroundColor: palette.blue.main,
      '&:disabled': {
        opacity: 0.4,
      },
    },
    success: {
      width: '100%',
      fontSize: '300%',
      marginBottom: '10px',
      color: `${palette.green.light}`,
    },
    warning: {
      width: '100%',
      fontSize: '300%',
      marginBottom: '10px',
      color: `${palette.informational.yellow}`,
    },
    failure: {
      width: '100%',
      fontSize: '300%',
      marginBottom: '10px',
      color: `${palette.informational.red}`,
    },
    modalText: {
      fontSize: '1.25rem',
    },
    modalList: {
      textAlign: 'initial',
    },
    modalButton: {
      display: 'inline-block !important',
      backgroundColor: palette.blue.main,
      color: palette.common.white,
      minWidth: '100px',
    },
  })

type StyleReturn = keyof ReturnType<typeof styles>
const useStyles = createUseStyles<Theme, StyleReturn>(styles)

const RsmUserAssignment = () => {
  const classes = useStyles()
  const {
    errors,
    // isSubmitting,
    setFieldValue,
    submitCount,
    values,
  }: FormikProps<FormModel> = useFormikContext()
  const rsmTeamAssignments = values.rsmTeamAssignments || []
  const originalAssignments = values.assignments || []
  const clientUsers = useSelector((state: AppState) => state.clientUsers.data)
  const clientId = useSelector((state: AppState) => state.clientUsers.clientId)
  const  clientIsLoading = useSelector((state: AppState) => state.clientUsers.isLoading)
  const cuCompleted = useSelector(
    (state: AppState) => state.clientUsers.completed
  )
  const { provisionUser, requestStatus, requestErrors } = useUsers()
  const [isUserErrModalOpen, setIsUserErrModalOpen] = useState(false)
  // this is to Assing a user to the app and organization
  const handleAddUser = async (user: UserProfileModel) => {
    const model: ProvisionUserRequestModel = {
      organizationId: clientId,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      claims: ['RSMAdmin'],
    }
    await provisionUser(model)
  }
  useEffect(() => {
    //if (isSubmitting && cuCompleted) {  // When the dialog window starts, isSubmitting is set to true; 
                                          // To enable this line, isSubmitting must be set to false if dialog window closes.
    if (cuCompleted) {
      const { rsmTeamAssignments: updatedAssignments } =
        getMergedClientUserAssignments(
          originalAssignments,
          rsmTeamAssignments,
          clientUsers
        )
      setFieldValue('rsmTeamAssignments', updatedAssignments)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientUsers, originalAssignments, setFieldValue])

  useEffect(() => {
    if (requestStatus === 'Failed') {
      setIsUserErrModalOpen(true)
    }
  }, [requestStatus])
  // close the error modal
  const handleUserErrModalClose = () => {
    setIsUserErrModalOpen(false)
  }

  return (
    <div>
      <span className={classes.title}>RSM User Assignments</span>
      {!!errors.rsmTeamAssignments && (
        <div className={classes.errorMessage}>{errors.rsmTeamAssignments}</div>
      )}
    
    <Loader ready={ !clientIsLoading}>
    <div>
      <Grid container xs='6'>
        <div
          className={clsx(
            classes.tableWrapper,
            errors.rsmTeamAssignments && submitCount > 0
              ? classes.error
              : classes.plain
          )}
        >
          <table className={classes.table}>
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th>Employee Name/Employee Number</th>
                {roles.map(({ label }) => (
                  <th key={label}>{label}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rsmTeamAssignments.map((user: TeamAssignment, i: number) => (
                <UserRow
                  key={user.userId}
                  rowNumber={i}
                  rowData={user}
                  setFieldValue={setFieldValue}
                />
              ))}
              {!rsmTeamAssignments.length && (
                <tr>
                  <td colSpan={roles.length + 2}>&nbsp;</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Grid>
      {clientId && (
        <FindUserButton
          onAddUser={handleAddUser}
          internalExternal={'Internal'}
          clientId={clientId}
          userDialogAdditional={
            'Enter in the Email, eNumber, First Name and or Last Name'
          }
          userDialogTitle={'Add a User to Client'}
        />
      )}
      </div></Loader>
      {
        // this is an error modal
      }
      {isUserErrModalOpen && (
        <UserErrModal
          handleModalClose={handleUserErrModalClose}
          userErrs={requestErrors}
          titleErr={'Errors in Assign/Add User:'}
        />
      )}
    </div>
  )
}

export default RsmUserAssignment
