import React from 'react'
import { createStyles, Theme } from '@rsmus/react-ui'
import { createUseStyles } from 'react-jss'

const styles = ({ palette }: Theme) =>
  createStyles({
    clientHeader: {
      alignItems: 'center',
      display: 'flex',
      flexWrap: 'nowrap',
    },
    sortSymbol: {
      borderRight: '5px solid transparent',
      borderBottom: `10px solid ${palette.common.black}`,
      borderLeft: '5px solid transparent',
      height: '0',
      marginRight: '5px',
      width: '0',
    },
  })

type StyleReturn = keyof ReturnType<typeof styles>
const useStyles = createUseStyles<Theme, StyleReturn>(styles)

const ComponentContent = () => {
  const classes = useStyles()
  return (
    <tr>
      <th className={classes.clientHeader}>
        {/* <span className={classes.sortSymbol} /> Hiding the sort symbol until the sort functionality is in place */}
        CLIENT
      </th>
      <th>ENGAGEMENT</th>
      <th className='text-center'>7216</th>
      <th className='text-center'>TAX YEAR</th>
      <th className='text-center'>TYPE</th>
      <th />
    </tr>
  )
}

export const TableHeader = ComponentContent
