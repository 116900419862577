import ky from '@rsmus/ky-auth'
import { Actions } from './actions'
import { ConfigurationSettings } from '../models'
import { AppThunk } from './store'
import { buildApiUrl } from '../apiUrl'

export const getConfigurationSettings = (): AppThunk => async dispatch => {
  try {
    const url = buildApiUrl('configuration/appsettings')
    const response = await ky.get(url)

    const settings: ConfigurationSettings[] = await response.json()
    dispatch(Actions.getConfigurationSettings(settings))
    dispatch(Actions.clearErrors())
  } catch (error) {
    dispatch(Actions.getConfigurationSettingsError(error))
  }
}
