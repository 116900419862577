import React from 'react'
import { createStyles, Button, Theme } from '@rsmus/react-ui'
import { Modal } from './Modal'
import { createUseStyles } from 'react-jss'
import { Loader } from '../AddNewUser/Loader'

const styles = () =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
    },
    modalContents: {
      paddingLeft: '3rem',
      paddingRight: '3rem',
    },
    button: {
      display: 'inline-block',
      width: '6rem',
    },
    row: {
      width: '15rem',
      margin: '0 auto',
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: '5%',
    },
  })

export interface ModalProps {
  children: React.ReactNode
  shown: boolean
  loading: boolean
  saving: boolean
  disabledSave: boolean
  onCancel: () => void
  onSave: () => void
}

type StyleReturn = keyof ReturnType<typeof styles>
const useStyles = createUseStyles<Theme, StyleReturn>(styles)

const ComponentContent = ({
  children,
  shown,
  loading,
  saving,
  disabledSave,
  onCancel,
  onSave,
}: ModalProps) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <Modal shown={shown} onClose={onCancel} alignment={'left'}>
        <div className={classes.modalContents}>
          {children}
          <div className={classes.row}>
            <Loader ready={loading === false && saving === false}>
              <Button
                variant='primary'
                onClick={onSave}
                type={'submit'}
                className={classes.button}
                disabled={loading || saving || disabledSave}
              >
                SAVE
              </Button>
            </Loader>
            <Button
              variant='standard'
              onClick={onCancel}
              className={classes.button}
            >
              CANCEL
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export const EditModal = ComponentContent
