const INITIAL_REDIRECT: string = 'initial-redirect'
const ROLL_FORWARD_PATH_START: string = '/engagements'

// Sets deeplink because AppContent doesn't always redirect to correct location after auth.
export const setDeepLink = () => {
  // We only store the location if we have a path.
  if (window.location.pathname !== '/') {
    // Regex to get the path + query (and anything else)
    const pathMatch = /(https?:\/\/)?[^/]+\/(.+)/i
    // Call the Regex on the currently URL to parse it.
    const path = pathMatch.exec(window.location.href)

    // It would be odd to not have a path, but if we don't, then make sure we post a warning.
    if (!path) {
      console.warn(`Could not parse window.location.`)
    } else {
      // Store just the path+query in local storage.
      window.localStorage.setItem(INITIAL_REDIRECT, path[2])
    }
  }
}

/** When the site is first visited, this returns the page that the user should be directed to.
 *   This circumvents the issue where the identity process only allows redirects to the base URL,
 *   but the user potentially entered the site using a deep link. */
export const getInitialPath = () => {
  // Get the deeplink from local storage, if we have one.
  const result = window.localStorage.getItem(INITIAL_REDIRECT)

  if (result) {
    // Be sure to clear the redirect for future visits to the site.
    window.localStorage.removeItem(INITIAL_REDIRECT)
    // Return the deeplink we found in local storage.
    return result
  }
  // No deeplink - just go to the default redirect location.
  return ROLL_FORWARD_PATH_START
}
