import React, { useCallback, useState } from 'react'
import { createStyles, Theme } from '@rsmus/react-ui'
import clsx from 'clsx'
import debounce from 'debounce'
import { createUseStyles } from 'react-jss'
import { useUsers } from '../../hooks/useUsers'
import { ParentOu, UserProfileModel } from '../../models'
import FindUserModal from './FindUserModal'

const styles = ({ palette, typogrphaphy }: Theme) =>
  createStyles({
    title: {
      ...typogrphaphy.bold,
      fontSize: '1.2rem',
      color: palette.grey[500],
    },
    tableWrapper: {
      width: '100%',
      margin: '1rem 0',
    },
    errorMessage: {
      color: palette.informational.red,
      margin: '1rem auto 0',
    },
    error: {
      border: `1px solid ${palette.informational.red}`,
    },
    plain: {
      border: '0px',
    },
    table: {
      width: '100%',
      '& thead': {
        '& th': {
          '&:first-child': {
            width: 50,
            textAlign: 'center',
          },
          '&:nth-child(2)': {
            textAlign: 'left',
            width: 300,
          },
          width: 100,
          textAlign: 'center',
          fontSize: '.8rem',
          wordWrap: 'normal',
          lineHeight: '.8rem',
          padding: '.5rem 0',
        },
      },
      '& tbody': {
        '& tr:nth-child(odd) td': {
          backgroundColor: palette.grey[100],
        },
        '& td': {
          verticalAlign: 'top',
          textAlign: 'center',
          padding: '5px 4px 4px',
          '&:nth-child(2)': {
            textAlign: 'left',
            paddingTop: 4,
          },
          '& span': {
            color: palette.grey[600],
          },
        },
      },
    },
    table2: {
      width: '100%',
      '& thead': {
        '& th': {
          '&:first-child': {
            width: 50,
            textAlign: 'center',
          },
          '&:nth-child(2)': {
            width: 300,
            textAlign: 'left',
          },
          fontSize: '.8rem',
          wordWrap: 'normal',
          lineHeight: '.8rem',
          padding: '.5rem 0',
        },
      },
      '& tbody': {
        '& tr:nth-child(odd) td': {
          backgroundColor: palette.grey[100],
        },
        '& td': {
          padding: '5px 4px 4px',
          '&:first-child': {
            textAlign: 'center',
            verticalAlign: 'top',
          },
          '&:nth-child(2)': {
            paddingTop: 4,
            verticalAlign: 'top',
          },
          '& span': {
            color: palette.grey[600],
          },
        },
      },
    },
    button: {
      zIndex: 1,
      padding: '0.5rem',
      minWidth: '8rem',
      border: 0,
      borderRadius: '0.25rem',
      marginRight: '1rem',
      marginLeft: '1rem',
    },
    '&:focus': {
      // TODO: Set a nicer style for focus outline
      outline: 'none',
    },
    buttonPrimary: {
      color: palette.common.white,
      backgroundColor: palette.blue.main,
      '&:disabled': {
        opacity: 0.4,
      },
    },
  })

type StyleReturn = keyof ReturnType<typeof styles>
const useStyles = createUseStyles<Theme, StyleReturn>(styles)
export interface FindUserButtonOwnProps {
  clientId: string
  internalExternal: ParentOu
  userDialogTitle?: string
  userDialogAdditional?: string
  onAddUser: (user: UserProfileModel) => Promise<void>
}

export const FindUserButton = ({
  clientId,
  internalExternal,
  userDialogAdditional,
  userDialogTitle,
  onAddUser,
}: FindUserButtonOwnProps) => {
  const { findUser, findResults, setFindResults, requestGetStatus } = useUsers()
  const [openFindUserDialog, setOpenFindUserDialog] = useState(false)
  const classes = useStyles()
  const primaryButtonClassName = clsx(classes.button, classes.buttonPrimary)
  // will open the user find modal
  const handleFindUserButtonClickOpen = () => {
    // clear the last on open.
    setFindResults([])
    setOpenFindUserDialog(true)
  }
  // Will Close the User Find Modal
  const handleFindUserModelClose = () => {
    setOpenFindUserDialog(false)
  }
  // will trigger a debounced fidn of hte user of the user.
  const handleSearchChange = (e: any) => {
    if (e.currentTarget) {
      if (e.currentTarget.value.length > 2 || e.currentTarget.value === '') {
        debouncedChangeQuery(e.currentTarget.value + '')
      }
    }
  }

  const handleAddUser = (user: UserProfileModel) => {
    setOpenFindUserDialog(false)// close find Dialog
    return onAddUser(user)
  }
  const debouncedChangeQuery = useCallback(
    debounce(
      (value: string) =>
        findUser({
          clientId: clientId,
          filter: value,
          parentOu: internalExternal,
        }),
      1000
    ),
    [clientId]
  )

  return (
    <React.Fragment>
      <button
        className={primaryButtonClassName}
        onClick={handleFindUserButtonClickOpen}
        type='button'
      >
        Add New User
      </button>
      {openFindUserDialog && (
        <FindUserModal
          userDialogTitle={userDialogTitle}
          userDialogAdditional={userDialogAdditional}
          internalExternal={internalExternal}
          requestGetStatus = {requestGetStatus}
          onAddUser={handleAddUser}
          users={findResults}
          onClose={handleFindUserModelClose}
          onSearchChanged={handleSearchChange}
        ></FindUserModal>
      )}
    </React.Fragment>
  )
}
