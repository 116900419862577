import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'abortcontroller-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as Icons from 'react-icons'

ReactDOM.render(
  <Icons.IconContext.Provider value={{ className: 'react-icons' }}>
    <App />
  </Icons.IconContext.Provider>,
  document.getElementById('root')
)
