import React from 'react'
import { createStyles, Button, Theme } from '@rsmus/react-ui'
import { Modal } from './Modal'
import { createUseStyles } from 'react-jss'

const styles = () =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
    },
    button: {
      display: 'inline-block',
      width: '4.5rem',
    },
    row: {
      width: '12rem',
      margin: '0 auto',
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: '10%',
    },
  })

export interface ModalProps {
  children: React.ReactNode
  shown: boolean
  onCancel: () => void
  onConfirm: () => void
}

type StyleReturn = keyof ReturnType<typeof styles>
const useStyles = createUseStyles<Theme, StyleReturn>(styles)

const ComponentContent = ({
  children,
  shown,
  onCancel,
  onConfirm,
}: ModalProps) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <Modal shown={shown} onClose={onCancel}>
        {children}
        <div className={classes.row}>
          <Button
           type ='button'
            variant='primary'
            onClick={onConfirm}
            className={classes.button}
          >
            Yes
          </Button>
          <Button
            type ='button'
            variant='primary'
            onClick={onCancel}
            className={classes.button}
          >
            No
          </Button>
        </div>
      </Modal>
    </div>
  )
}

export const ConfirmModal = ComponentContent
