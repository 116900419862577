import { createStyles, Theme } from '@rsmus/react-ui'
import React from 'react'
import {
  FaHeart,
  FaPencilAlt,
  FaTrashAlt,
  // FaTrashAlt
} from 'react-icons/fa'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { REACT_APP_TSAAPPURL } from '../../envVariables'
import { Engagement } from '../../models'
import { AppState } from '../../redux'
import HasPermission from '../HasPermission/HasPermission'
import { Checkbox } from '@material-ui/core'

const styles = ({ palette }: Theme) =>
  createStyles({
    noEngagements: {
      fontSize: '1.2rem',
      backgroundColor: `transparent !important`,
    },
    clientField: {
      width: '25%',
    },
    engagementField: {
      '& span': {
        whiteSpace: 'nowrap',
        color: palette.grey[600],
        fontSize: '.8rem',
      },
      '& span+span': {
        marginLeft: '1.5rem',
      },
    },
    engagementTaxYear: {
      width: '10%',
      textAlign: 'center',
    },
    engagementType: {
      width: '10%',
      textAlign: 'center',
    },
    engagementActions: {
      width: '15%',
      textAlign: 'right',
    },
    engagementName: {
      fontWeight: 700,
      margin: '0',
      '& a': {
        color: palette.blue.dark,
        textDecoration: 'none',
      },
      '& a:hover': {
        color: palette.blue.main,
      },
    },
    engagementButton: {
      display: 'inline-block',
      textAlign: 'center',
      backgroundColor: 'transparent',
      border: 'none',
      cursor: 'pointer',
      width: 44,
      padding: 10,
      borderRadius: '50%',
      color: palette.blue.dark,
      '&:active, &:focus': {
        outline: 'none',
      },
      '&:hover': {
        color: palette.blue.main,
        backgroundColor: palette.common.white,
      },
      '&:last-child': {
        marginRight: '0',
      },
      '&:disabled': {
        color: palette.grey[400],
        cursor: 'not-allowed',
      },
      '&> .heartEmpty': {
        fill: 'none',
        strokeWidth: '1em',
        stroke: palette.blue.dark,
      },
      '&> .heartFilled': {
        fill: palette.blue.dark,
      },
      '&:hover > .heartEmpty, &:hover > .heartFilled': {
        fill: palette.blue.main,
      },
    },
  })

export interface TableBodyProps {
  engagements: Array<Engagement | undefined>
  toggleFavorite: (id: string | number) => void
  showSoftDeleteModal: (engagementId: string | number) => void
}

const displayId = (id: string | number | undefined) =>
  id && id.toString().padStart(7, '0')

const displayMasterClientId = (engagement: Engagement) => {
  const clientId = engagement.clientId
  const masterId = engagement.client && engagement.client.masterId
  return `${displayId(masterId)} - ${displayId(clientId)}`
}

type StyleReturn = keyof ReturnType<typeof styles>
const useStyles = createUseStyles<Theme, StyleReturn>(styles)

const ComponentContent = ({
  engagements,
  toggleFavorite,
  showSoftDeleteModal,
}: TableBodyProps) => {
  const classes = useStyles()

  const tsaEngagementBaseUrl = REACT_APP_TSAAPPURL ? `${REACT_APP_TSAAPPURL}/engagements/` : ''

  const wrapEngagementName = ({ name, id }: { name: string; id: number }) => {
    if (!tsaEngagementBaseUrl) return name
    return (
      <a
        href={`${tsaEngagementBaseUrl}${id}`}
        target='_blank'
        rel='noopener noreferrer'
      >
        {name}
      </a>
    )
  }

  return (
    <>
      {engagements.length <= 0 && (
        <tr>
          <td className={classes.noEngagements} colSpan={5}>
            There are no engagements to view at this time.
          </td>
        </tr>
      )}

      {engagements.length > 0 &&
        engagements.map((engagement: Engagement | undefined) => {
          return (
            engagement && (
              <React.Fragment key={engagement.id}>
                <tr>
                  <td className={classes.clientField}>
                    {engagement.client && engagement.client.name}
                  </td>
                  <td className={classes.engagementField}>
                    <p className={classes.engagementName}>
                      {wrapEngagementName(engagement)}
                    </p>
                    <span>{displayMasterClientId(engagement)}</span>
                    <span>Version: {engagement.cchVersion}</span>
                  </td>
                  <td className={classes.engagementTaxYear}>

                    <Checkbox
                    checked={engagement.is7216Eligible}
                    disabled={true} 
                    />
                  </td>
                  <td className={classes.engagementTaxYear}>
                    {engagement.taxYear}
                  </td>
                  <td className={classes.engagementType}>
                    {engagement.engagementTaxForm}
                  </td>
                  <td className={classes.engagementActions}>
                    <button
                      type='button'
                      className={classes.engagementButton}
                      onClick={() => toggleFavorite(engagement.id)}
                      title='Toggle Favorite'
                    >
                      <FaHeart
                        className={
                          engagement.favorited ? 'heartFilled' : 'heartEmpty'
                        }
                      />
                    </button>
                    <Link
                      to={`engagements/${engagement.id}`}
                      className={classes.engagementButton}
                      title='Edit Engagement'
                    >
                      <FaPencilAlt />
                    </Link>
                    <HasPermission permission='DeleteEngagements'>
                      <button
                        type='button'
                        className={classes.engagementButton}
                        onClick={() => showSoftDeleteModal(engagement.id)}
                        disabled={engagement.phase !== 'setup'}
                      >
                        <FaTrashAlt />
                      </button>
                    </HasPermission>
                  </td>
                </tr>
              </React.Fragment>
            )
          )
        })}
    </>
  )
}

export const TableBody = ComponentContent
