import { createStyles, Modal, Theme } from '@rsmus/react-ui'
import * as React from 'react'
import { ParentOu, UserProfileModel } from '../../models'
import { createUseStyles } from 'react-jss'
import { FaSearch } from 'react-icons/fa'
import RenderFindUser from './RenderFindUser'
import { CreateUserButton } from './CreateUserButton'
import { Loader } from '../Loader'

const styles = ({ palette }: Theme) =>
  createStyles({
    modalBody: {
      alignItems: 'flex-start',
    },
    modalTitle: {
      paddingBottom: '1.3rem',
      textAlign: 'left',
      fontSize: '2.2rem',
      fontWeight: 'bold',
    },
    modalAdditional: {
      fontStyle: 'italic',
      textAlign: 'left',
      fontSize: '1rem',
    },
    modalSearch: {
      alignItems: 'center',
      display: 'flex',
      flexWrap: 'nowrap',
      width: '20.5rem',
      lineHeight: 1.4,
      fontSize: '1.3rem',
      backgroundColor: `${palette.grey[200]}`,
    },
    searchResults: {
      backgroundColor: '#d7d7d7',
      width: '31rem',
      height: '12rem',
      overflowX: 'hidden',
      overflowY: 'auto',
    },
    searchIcon: {
      color: `${palette.grey[400]}`,
      marginRight: '1rem',
    },
    inputBox: {
      width: '20rem',
      borderStyle: 'none',
      outline: 'none',
      marginLeft: '0.7rem',
      backgroundColor: `${palette.grey[200]}`,
    },
    noResult: {
      display: 'flex',
      alignItems: 'flex-start',
      color: palette.blue.main,
      padding: '0.38rem 1rem',
      lineHeight: '1.2',
      backgroundColor: '#d7d7d7',
      fontSize: 'medium',
    }
  })

export interface FindUserOwnProps {
  busy?: boolean
  users?: UserProfileModel[]
  userDialogTitle?: string
  userDialogAdditional?: string
  internalExternal: ParentOu
  requestGetStatus: string
  onClose: () => void
  onSearchChanged: (value: any) => void
  onAddUser: (user: UserProfileModel) => Promise<void>
}

type StyleReturn = keyof ReturnType<typeof styles>
const useStyles = createUseStyles<Theme, StyleReturn>(styles)

const FindUserModal = ({
  busy,
  users,
  userDialogTitle,
  userDialogAdditional,
  internalExternal,
  requestGetStatus,
  onClose,
  onSearchChanged,
  onAddUser,
}: FindUserOwnProps) => {
  const classes = useStyles()
  const usersToDisplay = users || []
  const [searchString, setSearchString] = React.useState('')
  const [displayNoResults, setDisplayNoResults] = React.useState(false)

  const addUserClick = (user: UserProfileModel) => {
    onAddUser(user)
  }
  const handleMeClose = () => {
    onClose() // close me
  }

  const handleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    setSearchString(e.currentTarget.value.toString())
    onSearchChanged(e)
  }
    
  React.useEffect(() => {
    if (searchString !=='' && usersToDisplay.length === 0) {
      setDisplayNoResults(true)
    }else{
      setDisplayNoResults(false)
    }
  }, [usersToDisplay])

  return (
    <Modal shown={true} onClose={() => onClose()}>
      <div id='find-user'>
        <div className={classes.modalBody}>
          <div className={classes.modalTitle}>{userDialogTitle}</div>
          <div className={classes.modalAdditional}>{userDialogAdditional}</div>
          <div className={classes.modalSearch}>
            <input
              type='text'
              className={classes.inputBox}
              value={searchString}
              onChange={e => handleOnChange(e)}
            />
            <FaSearch className={classes.searchIcon} />
            {internalExternal === 'External' && (
              <span>
                <CreateUserButton 
                handleCloseParent={handleMeClose}
                onAddUser={onAddUser} />
              </span>
            )}
          </div>

          <Loader ready={requestGetStatus !== 'Processing'}>
          {displayNoResults  && (
            <div className={classes.noResult}>
              No results found.
            </div>
          )}
          </Loader>
          
          <div className={classes.searchResults}>
            {usersToDisplay.map((user, index) => {
              return (
                <RenderFindUser
                  key={index}
                  user={user}
                  index={index.toString()}
                  disabled={user.status === 'DEPROVISIONED'}
                  onClick={() => addUserClick(user)}
                />
              )
            })}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default FindUserModal
