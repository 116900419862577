import { CircularProgress } from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import React from 'react'

export const Loader = ({
  ready,
  children,
}: {
  ready: boolean
  children: React.ReactNode
}) => {
  if (!ready) {
    return (
      <CircularProgress
        style={{
          color: green[500],
          position: 'absolute',
          top: '50%',
          left: '50%',
          marginTop: -12,
          marginLeft: -12,
          zIndex: 999,
        }}
      />
    )
  } else {
    return <React.Fragment>{children}</React.Fragment>
  }
}
