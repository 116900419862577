export function padLeadingZeroesCombined(
  clientId: string | number | undefined,
  masterId?: string | number | undefined
): string | void {
  if (!clientId) {
    return
  }
  let content: string = ''
  const paddedClientId = clientId.toString().padStart(7, '0')

  if (masterId) {
    const paddedMasterId = masterId.toString().padStart(7, '0')
    content += paddedMasterId + '-'
  } else{
    content += '0000000-' // prefix all zeros 
  }
  content += paddedClientId
  return content
}

// pads one value to 7 digits
export function padLeadingZeroes(
  valueToPadToSevenDigits: string | number
): string {
  const paddedValue = valueToPadToSevenDigits.toString().padStart(7, '0')
  return paddedValue
}