import { useContext } from 'react'
import { PingIdentityContext } from '@rsmus/react-pingidentity'

const RSM_ADMIN_ROLE = 'RSMAdmin'
const RSM_USER_ROLE = 'RSMUser'

export const useHasInternalClaim = () => {
  const { hasClaimInAnyOrg, hasClaims, loadingClaims } = useContext(
    PingIdentityContext
  )

  const authorized = hasClaims
    ? hasClaimInAnyOrg(RSM_ADMIN_ROLE) || hasClaimInAnyOrg(RSM_USER_ROLE)
    : undefined

  return { authorized, isLoading: loadingClaims }
}
