import {
  ClientAssignment,
  ClientEngagements,
  ClientUser,
  ClientUserMetadata,
  Engagement,
  EngagementTemplate,
  Index,
} from '../models'
import * as actions from './actions'

export type AppConfigurationState = Index<any>

export type EngagementsState = {
  data: Index<Engagement>
  error?: Error | undefined
  isLoading: boolean
}

export type UserManagementState = Index<any>

export type UserInfoState = Index<any>

export type UserPermissionsState = {
  enabled?: boolean
  permissions?: string[]
}

export type OrganizationsFilterState = Index<string[]>

export type ValidationState = {
  errors?: string[]
  status?: Number
  duplicate?: boolean
}

const defaultEngagementState: EngagementsState = { data: {}, isLoading: false }

export function validationReducer(
  state: ValidationState = {},
  action: actions.Actions
): ValidationState {
  switch (action.type) {
    case actions.SAVE_ERRORS:
      return {
        ...state,
        errors: action.payload.validationErrors,
      }
    case actions.CLEAR_ERRORS:
      return {
        ...state,
        errors: [],
        status: -1,
        duplicate: false,
      }
    case actions.SET_STATUS:
      return {
        ...state,
        status: action.payload,
      }
    case actions.SET_DUPLICATE:
      return {
        ...state,
        duplicate: action.payload,
      }

    default:
      return state
  }
}

export function appConfigurationReducer(
  state: AppConfigurationState = {},
  action: actions.Actions
): AppConfigurationState {
  switch (action.type) {
    case actions.GET_CONFIGURATION_SETTINGS:
      return { ...state, configurationSettings: action.payload }
    default:
      return state
  }
}

export function engagementsReducer(
  state: EngagementsState = defaultEngagementState,
  action: actions.Actions
): EngagementsState {
  switch (action.type) {
    case actions.GET_ENGAGEMENT_BEGIN:
    case actions.SAVE_ENGAGEMENT_BEGIN:
    case actions.GET_ALL_ENGAGEMENTS_BEGIN:
    case actions.UPDATE_ENGAGEMENT_PHASE_BEGIN:
      return { ...state, error: undefined, isLoading: true }
    case actions.GET_ALL_ENGAGEMENTS_END:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      }
    case actions.GET_ENGAGEMENT_END:
    case actions.SAVE_ENGAGEMENT_END:
      return {
        ...state,
        data: { ...state.data, [action.payload.id]: action.payload },
        isLoading: false,
      }
    case actions.UPDATE_ENGAGEMENT_PHASE_ERROR:
    case actions.GET_ENGAGEMENT_ERROR:
    case actions.SAVE_ENGAGEMENT_ERROR:
    case actions.GET_ALL_ENGAGEMENTS_ERROR:
      return { ...state, error: action.payload, isLoading: false }
    case actions.TOGGLE_FAVORITE_ENGAGEMENT_END: {
      return {
        ...state,
        data: { ...state.data, [action.payload.id]: action.payload },
      }
    }
    case actions.UPDATE_ENGAGEMENT_PHASE_END:
      // We need to update the appropriate engagement with the new phase:

      // Get the engagement to update.
      var targetEngagement = state.data[action.payload.engagementId]

      // If the engagement doesn't exist  - that'd be weird - but just return the state then.
      if (!targetEngagement) {
        return state
      }

      // Reconstruct the state with the new phase on the target engagement.
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.engagementId as string]: {
            ...targetEngagement,
            phase: action.payload.phase,
          },
        },
        isLoading: false,
      }
    default:
      return state
  }
}

export function userInfoReducer(
  state: UserInfoState = { loadComplete: false },
  action: actions.Actions
): UserInfoState {
  switch (action.type) {
    case actions.GET_USER_INFO:
      return { ...action.payload, loadComplete: true }
    default:
      return state
  }
}

export function userPermissionsReducer(
  state: UserPermissionsState = {},
  action: actions.Actions
): UserPermissionsState {
  switch (action.type) {
    case actions.GET_USER_PERMISSIONS_END:
      return { ...action.payload }
    default:
      return state
  }
}

export function organizationsFilterReducer(
  state: OrganizationsFilterState = {},
  action: actions.Actions
): OrganizationsFilterState {
  switch (action.type) {
    case actions.GET_ORGANIZATION_FILTERS:
      return { ...state, organizations: action.payload }
    default:
      return state
  }
}

export function userManagementReducer(
  state: UserManagementState = {},
  action: actions.Actions
): UserManagementState {
  switch (action.type) {
    case actions.GET_USER_PROFILES:
      return { ...state, userManagementProfiles: action.payload }
    default:
      return state
  }
}

export type TaxFormsState = EngagementTemplate[]

export function taxFormsReducer(
  state: TaxFormsState = [],
  action: actions.Actions
) {
  switch (action.type) {
    case actions.GET_TAX_FORMS_END:
      return [...action.payload]
    default:
      return state
  }
}

export type ClientTeamAssignmentsState = {
  isLoading: boolean
  data: ClientAssignment[]
}

export function clientTeamAssignmentsReducer(
  state: ClientTeamAssignmentsState = { isLoading: false, data: [] },
  action: actions.Actions
): ClientTeamAssignmentsState {
  switch (action.type) {
    case actions.CLEAR_CLIENT_TEAM_ASSIGNMENTS:
      return { ...state, data: [] }
    case actions.GET_CLIENT_TEAM_ASSIGNMENTS_BEGIN:
      return { ...state, isLoading: true }
    case actions.GET_CLIENT_TEAM_ASSIGNMENTS_END:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      }
    default:
      return state
  }
}

export type ClientUserState = {
  clientUserMetadata: ClientUserMetadata
  clientUserEngagements: ClientEngagements[]
  error?: Error
}

const defaultState: ClientUserState = {
  clientUserMetadata: {},
  clientUserEngagements: [],
  error: undefined,
}

export function clientUserReducer(
  state: ClientUserState = defaultState,
  action: actions.Actions
): ClientUserState {
  switch (action.type) {
    case actions.GET_CLIENT_USER_ENGAGEMENTS_BEGIN:
      return {
        ...state,
        ...defaultState,
      }
    case actions.GET_CLIENT_USER_ENGAGEMENTS_END:
      return {
        ...state,
        clientUserMetadata: action.payload.clientUserMetadata,
        clientUserEngagements: action.payload.clientEngagements,
        error: undefined,
      }

    case actions.POST_CLIENT_USER_ASSIGNMENTS_BEGIN:
      return {
        ...state,
      }

    case actions.POST_CLIENT_USER_ASSIGNMENTS_END:
      return {
        ...state,
        error: undefined,
      }

    case actions.POST_CLIENT_USER_ASSIGNMENTS_ERROR:
      return {
        ...state,
        error: new Error(action.payload.message),
      }

    default:
      return state
  }
}

export type ClientUsersState = {
  isLoading: boolean
  completed: boolean
  data: ClientUser[]
  clientId: string
}

const defaultClientUsersState: ClientUsersState = {
  isLoading: false,
  completed: false,
  data: [],
  clientId: '',
}

export function clientUsersReducer(
  state: ClientUsersState = defaultClientUsersState,
  action: actions.Actions
): ClientUsersState {
  switch (action.type) {
    case actions.GET_CLIENT_USERS_BEGIN:
      return {
        ...state,
        isLoading: true,
        completed: false,
      }
    case actions.GET_CLIENT_USERS_END:
      return {
        ...state,
        isLoading: false,
        completed: true,
        data: action.payload,
      }
    case actions.GET_CLIENT_USERS_ERROR:
      return {
        ...state,
        isLoading: false,
        completed: true,
        data: [],
      }
    case actions.CLEAR_CLIENT_USERS:
      return {
        ...state,
        data: [],
      }
    case actions.SET_CLIENT_ID:
      return {
        ...state,
        clientId: action.payload,
      }
    case actions.SET_CLIENT_USER_STATUS:
      const row = state.data.findIndex(
        (t) => t.userId === action.payload.userId
      )
      const userValue = state.data[row]
      const newData = [...state.data]
      newData[row] = { ...userValue, status: action.payload.status }

      return {
        ...state,
        isLoading: false,
        completed: true,
        data: newData,
      }
    default:
      return state
  }
}
