import { createStyles, Theme } from '@rsmus/react-ui'
import clsx from 'clsx'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, AppState, thunks } from '../../redux'
import { createUseStyles } from 'react-jss'
import { NavLink, useLocation } from 'react-router-dom'
import { FaTimesCircle } from 'react-icons/fa'
import { RedirectModal } from '../Shared/RedirectModal'
import { AuthorizedClientAccessApprover } from '../AuthorizedClientAccessApprover'
import AddEngagementButton from '../AddEngagementButton'

const styles = ({ palette, typogrphaphy, transitions }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flex: 1,
      color: palette.common.white,
      backgroundColor: palette.blue.main,
      alignItems: 'center',
      // padding: '0 2rem',
      paddingLeft: '2rem',
      'z-index': '1001',
    },
    modalContainer: {
      bottom: 0,
      left: 0,
      position: 'absolute',
      right: 0,
      top: 0,
    },
    modalText: {
      ...typogrphaphy.normal,
      color: palette.common.black,
      fontSize: '1.2rem',
      textAlign: 'center',
      '& h1': {
        ...typogrphaphy.normal,
        fontSize: '2rem',
        margin: '15px 0',
      },
    },
    unauthorized: {
      color: palette.informational.red,
      fontSize: '5rem',
    },
    modalLink: {
      ...typogrphaphy.bold,
      backgroundColor: palette.grey[300],
      borderRadius: '3px',
      color: palette.common.black,
      display: 'block',
      fontSize: '1rem',
      margin: '15px auto 0',
      maxWidth: '150px',
      padding: '15px 0',
      textAlign: 'center',
      textDecoration: 'none',
      '&:focus, &:hover': {
        color: palette.common.black,
        textDecoration: 'none',
      },
    },
    title: {
      fontSize: '1.25rem',
      margin: 0,
      ...typogrphaphy.light,
    },
    fill: {
      flex: 1,
    },
    tab: {
      display: 'inline-block',
      alignSelf: 'flex-end',
      padding: '0.8rem',
      minWidth: '10rem',
      textAlign: 'center',
      transition: transitions.create(),
      cursor: 'pointer',
    },
    tabNav: {
      color: palette.grey['700'],
      backgroundColor: palette.grey['300'],
      marginLeft: '0.25rem',
      '&:hover': {
        color: palette.grey['700'],
        backgroundColor: palette.grey['400'],
        textDecoration: 'none',
      },
      '&.active': {
        color: palette.common.black,
        backgroundColor: palette.common.white,
        ...typogrphaphy.bold,
      },
      '&:hover.active': {
        cursor: 'default',
        backgroundColor: palette.common.white,
      },
    },
    tabNew: {
      color: palette.common.white,
      backgroundColor: palette.blue.dark,
      marginLeft: '1rem',
    },
  })

type StyleReturn = keyof ReturnType<typeof styles>
const useStyles = createUseStyles<Theme, StyleReturn>(styles)

const AppHeader = () => {
  const classes = useStyles()
  const dispatch = useDispatch<AppDispatch>()
  const appConfiguration: any | undefined = useSelector(
    (state: AppState) => state.appConfiguration
  )

  const userInfo: any | undefined = useSelector(
    (state: AppState) => state.userInfo
  )
  const navTabClassName = clsx(classes.tab, classes.tabNav)

  useEffect(() => {
    if (userInfo && userInfo.isInternal) {
      dispatch(thunks.appConfiguration.getConfigurationSettings())
    }
  }, [dispatch, userInfo])

  useEffect(() => {
    dispatch(thunks.userManagementThunks.getUser())
  }, [dispatch])

  const { pathname } = useLocation()

  return (
    <div className={classes.root}>
      {userInfo && userInfo.loadComplete && !userInfo.isInternal && (
        <div className={classes.modalContainer}>
          <RedirectModal shown={true}>
            <div>
              <div className={classes.modalText}>
                <FaTimesCircle className={classes.unauthorized} />
                <h1>Unauthorized Access</h1>
                <p>
                  You haven't been granted access for this section.
                  <br />
                  Please contact your admin to have permissions updated.
                </p>
              </div>
              {appConfiguration && appConfiguration.configurationSettings && (
                <a
                  className={classes.modalLink}
                  href={appConfiguration.configurationSettings.portalAppUrl}
                  target='_self'
                >
                  CLOSE
                </a>
              )}
              {(!appConfiguration ||
                !appConfiguration.configurationSettings) && (
                <a
                  className={classes.modalLink}
                  href='https://tsaportaldev3.rsmus.com/'
                  target='_self'
                >
                  CLOSE
                </a>
              )}
            </div>
          </RedirectModal>
        </div>
      )}

      {userInfo && userInfo.isInternal && (
        <>
          <h1 className={classes.title}>Engagement Management</h1>
          <div className={classes.fill} />
          <AuthorizedClientAccessApprover>
          <NavLink className={navTabClassName} to='/accessrequests'>
            Access Requests
          </NavLink>
          </AuthorizedClientAccessApprover>
          <NavLink className={navTabClassName} to='/engagements'>
            Engagements
          </NavLink>
        
          <AddEngagementButton />
        </>
      )}
    </div>
  )
}

export default AppHeader
