import React, { useState } from 'react'
import { createStyles, Theme } from '@rsmus/react-ui'
import clsx from 'clsx'
import { createUseStyles } from 'react-jss'
const styles = ({ palette, typogrphaphy }: Theme) =>
  createStyles({
    button: {
      zIndex: 1,
      // fontSize: '1.0rem',
      // padding: '0.5rem',
      minWidth: '8rem',
      width: '10rem',
      // height: '2rem',
      border: 0,
      borderRadius: '0.25rem',
      marginRight: '1rem',
      marginLeft: '1rem',
    },
    '&:focus': {
      // TODO: Set a nicer style for focus outline
      outline: 'none',
    },
    buttonPrimary: {
      color: palette.common.white,
      backgroundColor: palette.blue.main,
      '&:disabled': {
        opacity: 0.4,
      },
    },
  })

type StyleReturn = keyof ReturnType<typeof styles>
const useStyles = createUseStyles<Theme, StyleReturn>(styles)
export interface GridButtonOwnProps {
  buttonCaption:string
 
}
export const GridButton = ({buttonCaption}:GridButtonOwnProps
  ) => {

  const classes = useStyles()
  const primaryButtonClassName = clsx(classes.button, classes.buttonPrimary)
  // will open the user find modal


  return (
    <React.Fragment>
      <button
        className={primaryButtonClassName}
        type='button'
        
      >
        {buttonCaption}
      </button>
      
    </React.Fragment>
  )
}
