import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Authorized } from '../Authorized' 
import { AppContainerWithAccountMenu } from '@rsmus/react-ui'
import { AppState } from '../../redux'
import AppHeader from '../AppHeader'
import AppContent from '../AppContent'
import { FaPenSquare, FaUser } from 'react-icons/fa'
import { REACT_APP_ENGAGEMENTMANAGERURL, REACT_APP_TSAAPPURL } from '../../envVariables'
import { ensureNumber } from '@rsmus/guards'
import { useLocation } from 'react-router'

export interface SupportLink {
    text: string
    url: string
  }

const AccountMenu = () => {
  const engagements = useSelector((state: AppState) => state.engagements)
  const location = useLocation()
  //let taxForm = ''
  //let taxYear = 0
  let enagementId = 0

  const applicationLinks = [
    {
      text: 'TSA Hub',
      icon: <FaPenSquare />,
      url: REACT_APP_TSAAPPURL,
    },
    {
      text: 'Engagement Manager',
      icon: <FaUser />,
      url: REACT_APP_ENGAGEMENTMANAGERURL,
    }
  ]

  let tempsupportLinks : SupportLink[] = []    
  const [showGlossary, SetShowGlossary] = React.useState<boolean>(false)
  const [supportLinks, setSupportLinks] = useState<SupportLink[]>(tempsupportLinks)
  const [taxForm, setTaxForm] = useState<string>('')
  const [taxYear, setTaxYear] = useState<string>('')

  React.useEffect(() => {
    if(Object.keys(engagements.data).length > 0){
        const params = location.pathname.trim().split('/')
        if(params.length > 2)
        {
            if (!showGlossary) {
                enagementId = ensureNumber(params[2])
                const engagement = engagements.data[enagementId]
                if(engagement){
                    if(engagement?.engagementTaxForm !== '' && engagement?.taxYear !== '')
                    {
                      setTaxForm(engagement?.engagementTaxForm || '') 
                      setTaxYear(engagement?.taxYear) 
                      if(showGlossary || engagement?.template?.hasGlossary)
                      { SetShowGlossary(true) }
                      else { SetShowGlossary(false) }
                    }
                }
            }
        }
        else{ 
          SetShowGlossary(false) 
        }
    }

    const requestAssistance: SupportLink = {
      text: 'Request Assistance',
      url: REACT_APP_TSAAPPURL + '/help'
    }
    const glossary: SupportLink = {
      text: 'Glossary',
      url: `${REACT_APP_TSAAPPURL}/help/glossary/formName/${taxForm}/taxYear/${ensureNumber(taxYear)}`
    }
    const whatsnew: SupportLink = {
      text: "What's New",
      url: REACT_APP_TSAAPPURL + '/help/whatsnew'
    }
  
    tempsupportLinks.push(requestAssistance)
    if (showGlossary) {
      tempsupportLinks.push(glossary)
    }
    tempsupportLinks.push(whatsnew)

    setSupportLinks(tempsupportLinks)

  }, [engagements, showGlossary])

  return (
    <Authorized>
        <AppContainerWithAccountMenu 
            currentEnvironment={'Engagement Manager'}
            support={supportLinks}
            // accountLinks={accountLinks}
            links={applicationLinks}
            variant='strong'
            headerComponent={<AppHeader />}
            contentComponent={<AppContent />}
        />
    </Authorized>
  )
}

export default AccountMenu
