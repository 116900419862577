import { QueryStringParameters, joinSegments, queryString } from './url'
import { 
        REACT_APP_TSAAPIBASEURL,
        REACT_APP_TSAAGGREGATORURL
        } from './envVariables'

const DEFAULT_API_VERSION = '1'
const V2_API_VERSION = '2'

let apiVersion = DEFAULT_API_VERSION
let v2ApiVersion = V2_API_VERSION

export function setApiVersion(version: string) {
  apiVersion = version
}

export function getApiVersion() {
  return apiVersion
}

export function buildApiUrl(path: string, params?: QueryStringParameters) {
  return buildUrl(
    path,
    joinSegments(REACT_APP_TSAAPIBASEURL || ''),
    apiVersion,
    params)
}

export function buildV2ApiUrl(path: string, params?: QueryStringParameters) {
  return buildUrl(
    path,
    joinSegments(REACT_APP_TSAAPIBASEURL || ''),
    v2ApiVersion,
    params)
}

export function buildAggregatorUrl(
  path: string,
  params?: QueryStringParameters
) {
  return buildUrl(
    path,
    joinSegments(REACT_APP_TSAAGGREGATORURL || ''),
    apiVersion,
    params
  )
}

function buildUrl(path: string, route: string, version: string, params?: QueryStringParameters) {
  if (path.indexOf('?') > -1) {
    throw new Error('Search parameters must be passed in the params parameter.')
  }

  let url = isSignalRPath(path)
    ? joinSegments(route, path)
    : joinSegments(route, `v${version}`, path)

  if (params) {
    url += queryString(params)
  }

  return url
}

function isSignalRPath(path: string) {
  return /^\/?hub/i.test(path)
}
