import * as React from 'react'
import { useTheme, createUseStyles } from 'react-jss'
import { AppContainer, Theme } from '@rsmus/react-ui'

const useStyles = createUseStyles(({ palette, typogrphaphy }: Theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    backgroundColor: palette.grey[100],
    alignItems: 'center',
    justifyContent: 'center',
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    padding: '4rem 8rem',
    backgroundColor: palette.common.white,
    borderRadius: 12,
    border: `3px solid ${palette.grey[300]}`,
    '&> h1': {
      fontSize: '14rem',
      color: palette.grey[200],
      lineHeight: 1,
    },
    '&> h3': {
      fontSize: '1.4rem',
      color: palette.grey[500],
      fontFamily: typogrphaphy.normal.fontFamily,
    },
  },
}))

const MessageBox = () => {
  const theme = useTheme()
  const classes = useStyles(theme!)
  return (
    <div className={classes.root}>
      <div className={classes.info}>
        <h1>401</h1>
        <h3>You are not authorized to use this application.</h3>
      </div>
    </div>
  )
}

export const NotAuthorized = () => (
  <AppContainer contentComponent={<MessageBox />} />
)

export default NotAuthorized
