import ky from '@rsmus/ky-auth'
import { ensureNumber } from '@rsmus/guards'
import { Actions } from './actions'
import { AppThunk } from './store'
import { convertCountryCodeToName } from './helpers'
import { IndexKey, Engagement, Index, EngagementPhaseTypes } from '../models'
import moment from 'moment'
import { buildAggregatorUrl, buildApiUrl, buildV2ApiUrl } from '../apiUrl'
const engagementLargeMilliSecondTimeOut = 300000 // 300 seconds 5 Minutes
const engagementMedMilliSecondTimeOut = 60000 // 60 seconds 1 Minute
export const sortByKey = (key: string) => (a: any, b: any) =>
  a[key].localeCompare(b[key])

let controller = new AbortController()

export const getAllEngagements = (): AppThunk => async (dispatch) => {
  controller.abort()

  try {
    dispatch(Actions.getAllEngagementsBegin())

    const response = await ky.get(buildApiUrl('engagements'), {
      timeout: engagementLargeMilliSecondTimeOut,
    })
    const engagements: Array<Engagement> = await response.json()
    const data: Index<Engagement> = engagements.reduce(
      (acc: Index<Engagement>, engagement: Engagement) => {
        return { ...acc, [engagement.id]: engagement }
      },
      {}
    )

    dispatch(Actions.getAllEngagementsEnd(data))
  } catch (error) {
    dispatch(Actions.getAllEngagementsError(error))
  }
}

export const getById =
  (id: IndexKey): AppThunk =>
  async (dispatch) => {
    controller.abort()

    try {
      dispatch(Actions.getEngagementBegin())

      const response = await ky.get(buildV2ApiUrl(`engagements/${id}`), {
        timeout: engagementMedMilliSecondTimeOut,
      })
      const engagement: Engagement = await response.json()

      // transform to engagement object
      dispatch(Actions.getEngagementEnd(engagement))
      dispatch(Actions.clearErrors())
    } catch (error) {
      dispatch(Actions.getEngagementError(error))
    }
  }

export const getClientTeamAssignments =
  (clientId: IndexKey): AppThunk =>
  async (dispatch) => {
    dispatch(Actions.getClientTeamAssignmentsBegin())

    const url = buildApiUrl(`/clientassignments?clientId=${clientId}`)
    const response = await ky.get(url)

    const content = await response.json()

    dispatch(
      Actions.getClientTeamAssignmentsEnd(content.sort(sortByKey('email')))
    )
  }

export const getTaxForms = (): AppThunk => async (dispatch) => {
  dispatch(Actions.getTaxFormsBegin())

  const url = buildApiUrl('taxformcontent')
  const response = await ky.get(url, {
    timeout: engagementMedMilliSecondTimeOut,
  })

  const content = await response.json()

  dispatch(Actions.getTaxFormsEnd(content))
}

export const checkDuplicateEngagementAndSave =
  (
    engagement: any,
    rollForward: boolean = false,
    sourceEngagement: any = null
  ): AppThunk =>
  async (dispatch) => {
    try {
      const response = await ky(buildApiUrl('engagements/isduplicate'), {
        method: 'put',
        json: engagement,
        throwHttpErrors: true,
        timeout: engagementMedMilliSecondTimeOut,
      })
      const duplicate = await response.json()
      /*  let warn: ErrorObject
    warn = {}
    warn.validationErrors = [] */
      if (duplicate === true) {
        dispatch(Actions.setDuplicate(true))
      } else {
        dispatch(Actions.setDuplicate(false))
        if (rollForward) {
          dispatch(saveEngagement(engagement, true, sourceEngagement))
        } else {
          dispatch(saveEngagement(engagement))
        }
        // setIsLoading(true) // not sure when this should happen.
      }
    } catch (error) {
      dispatch(Actions.saveEngagementError(error))
      dispatch(Actions.setStatus(500))
    }
  }
export const saveEngagement =
  (
    engagement: any,
    rollForward: boolean = false,
    sourceEngagement: any = null
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(Actions.saveEngagementBegin())

      const id = ensureNumber(engagement.id)
      const method = id <= 0 ? 'post' : 'put'

      const response = await ky(buildAggregatorUrl('/engagements'), {
        method,
        json: engagement,
        throwHttpErrors: false,
        timeout: engagementMedMilliSecondTimeOut,
      })
      const saved = await response.json()
      if (response.status === 400) {
        dispatch(Actions.saveErrors(saved))
        dispatch(Actions.setStatus(400))
      } else {
        dispatch(Actions.saveEngagementEnd(saved))
        dispatch(Actions.setStatus(200))
        if (rollForward) {
          sourceEngagement.rollForwardDate = moment().toISOString()
          dispatch(saveEngagement(sourceEngagement))
          dispatch(toggleFavorite(saved.id))
          dispatch(getLastYearsData(saved.id))
        }
      }
    } catch (error) {
      dispatch(Actions.saveEngagementError(error))
      dispatch(Actions.setStatus(500))
    }
  }

export const getLastYearsData =
  (engagementId: number | string): AppThunk =>
  async (dispatch, getState) => {
    try {
      engagementId = ensureNumber(engagementId)

      dispatch(Actions.getLastYearsDataBegin())

      const engagement = getState().engagements.data[engagementId]
      if (!engagement) {
        throw new Error('Could not find engagement')
      }

      const url = buildApiUrl(`lastyearanswers/initiateexport`, {
        engagementId: engagementId,
      })
      await ky(url, { method: 'post' })
      dispatch(Actions.getLastYearsDataEnd())
    } catch (error) {
      dispatch(Actions.getLastYearsDataError(error))
    }
  }

export const toggleFavorite =
  (engagementId: string | number): AppThunk =>
  async (dispatch) => {
    try {
      // dispatch(Actions.toggleFavoriteEngagementBegin(engagementId))

      // api call to favorite
      const engagement = await ky(
        buildApiUrl(`engagements/${engagementId}/toggleFavorite`),
        {
          method: 'put',
        }
      )
      const saved = await engagement.json()
      // this method takes engagement type, might need to normalize
      dispatch(Actions.toggleFavoriteEngagementEnd(saved))
    } catch (error) {
      // error handling
      dispatch(Actions.toggleFavoriteEngagementError(error))
    }
  }

export const softDeleteEngagement =
  (engagementId: string | number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(Actions.softDeleteEngagementBegin())
      await ky(buildApiUrl(`engagements/${engagementId}/softdelete`), {
        method: 'post',
      })
      dispatch(Actions.softDeleteEngagementEnd())
      dispatch(getAllEngagements())
    } catch (error) {
      dispatch(Actions.softDeleteEngagementError(error))
    }
  }

export const clearErrors = (): AppThunk => (dispatch) => {
  dispatch(Actions.clearErrors())
}

/** This only updates the phase of an engagement, but does not update
 *   milestones or perform other processing. */
export const updateEngagementPhase =
  (engagementId: string | number, phase: EngagementPhaseTypes): AppThunk =>
  async (dispatch) => {
    // Inform Redux we're updating the engagement's phase.
    dispatch(Actions.updateEngagementPhaseBegin(engagementId, phase))

    try {
      // Tell the API to update the engagement's phase.
      await ky(
        buildApiUrl(
          `engagements/updateengagementphase/${engagementId}/${phase}`
        ),
        {
          method: 'put',
        }
      )

      // Inform the state we've succeeded at updating the phase.
      dispatch(Actions.updateEngagementPhaseEnd(engagementId, phase))
    } catch (err) {
      // Indicate we had an error during the call to update the engagement's phase.
      dispatch(Actions.updateEngagementPhaseError(err))
    }
  }
