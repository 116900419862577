import { PingIdentityContext } from '@rsmus/react-pingidentity'
import React, { ReactNode, useEffect } from 'react'
import { useClientRequests} from '../hooks/useClientRequests'

type IsAuthorizedProps = {
  children: ReactNode
}

export const AuthorizedClientAccessApprover = ({ children }: IsAuthorizedProps) => {
  const { hasApproverAccess,isClientAccessApprover  } = useClientRequests()
  // get all  of clientRequests
  useEffect(() => {
    hasApproverAccess()
    // eslint-disable-next-line
  }, [])

  if (!isClientAccessApprover) {
    return null
  }

 
  return <>{children}</>
}
