import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import {
  createStyles,
  Grid,
  Theme,
  CheckboxField,
  useFormikContext,
  FormikProps,
} from '@rsmus/react-ui'
import {
  Dictionary,
  ProvisionUserRequestModel,
  TeamAssignment,
  UserProfileModel,
} from '../../models'
import { AppState, thunks, Actions, AppDispatch } from '../../redux'
import { FormModel } from '../EngagementScene/models'
import { getMergedClientUserAssignments } from '../EngagementScene/formHelpers'
import { FindUserButton } from '../AddNewUser/FindUserButton'
import { useUsers } from '../../hooks/useUsers'
import { UserErrModal } from '../AddNewUser/UserErrModal'
import { ParentOu, UserAction } from './userAction'
import { Loader } from '../Loader'
import { string } from 'yup'

const styles = ({ palette, typogrphaphy }: Theme) =>
  createStyles({
    title: {
      ...typogrphaphy.bold,
      fontSize: '1.2rem',
      color: palette.grey[500],
    },
    tableWrapper: {
      width: '100%',
      margin: '1rem 0',
    },
    errorMessage: {
      color: palette.informational.red,
      margin: '1rem auto 0',
    },
    error: {
      border: `1px solid ${palette.informational.red}`,
    },
    plain: {
      border: '0px',
    },
    table: {
      width: '100%',
      '& thead': {
        '& th': {
          '&:first-child': {
            width: '5rem',
            textAlign: 'center',
          },
          fontSize: '.8rem',
          wordWrap: 'normal',
          lineHeight: '.8rem',
          padding: '.5rem .25rem',
        },
      },
      '& tbody': {
        '& tr:nth-child(odd) td': {
          backgroundColor: palette.grey[100],
        },
        '& td': {
          verticalAlign: 'top',
          padding: '.25rem',
          '&:first-child': {
            textAlign: 'center',
            paddingTop: 5,
            '& label': {
              paddingLeft: 0,
            },
          },
          '& span': {
            color: palette.grey[600],
          },
        },
      },
    },
  })

interface UserRowProps {
  rowNumber: number
  rowData: TeamAssignment
}

const dateTimeFormatter = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
})

export const formatDate = (date: Date | undefined) => {
  if (!date) {
    return ''
  }

  const dateValue = Date.parse(date as any)
  return dateTimeFormatter.format(dateValue)
}

const UserRow = ({ rowNumber, rowData }: UserRowProps) => {
  const userType = rowData.type as ParentOu
  const userStatus = rowData.status as string
  const userId = rowData.userId as string
  const rsmIsFederated = rowData.rsmIsFederated as boolean
  const [isOktaErrModalOpen, setIsOktaErrModalOpen] = useState(false)
  const [lastAction, setLastAction] = useState('')
  const dispatch = useDispatch<AppDispatch>()
  const {
    reactivateExternalUser,
    resetPasswordExternalUser,
    unlockExternalUser,
    userResult,
    requestGetStatus,
  } = useUsers()

  const GenericErrorMessage: Dictionary<string> = {
    1: 'An error prevented your request from processing. Please refresh the page and try again. If you continue to encounter a problem, contact RSM for assistance.',
  }
  const handleResendWelcome = async (userId: string) => {
    setLastAction('RESEND ACTIVATION EMAIL')
    await reactivateExternalUser(userId)
  }

  const handleUnlock = async (userId: string) => {
    setLastAction('UNLOCK USER')
    await unlockExternalUser(userId)
  }

  const handleResetPassword = async (userId: string) => {
    setLastAction('RESET PASSWORD')
    await resetPasswordExternalUser(userId)
  }
  useEffect(() => {
    if (requestGetStatus === 'Failed') {
      setIsOktaErrModalOpen(true)
    }
  }, [requestGetStatus])
  const handleCloseOktaModal = () => {
    setIsOktaErrModalOpen(false)
  }

  // This will detect  user result changes from user action buttons then dispatch a status update to the store for the user status.
  useEffect(() => {
    if (userResult?.rsmUid) {
      console.log(userResult?.rsmUid)
      if (userId === userResult?.rsmUid) {
        // we add sucess on hte end so the user is not attempted to do again.
        // hte sucess will cause the button to disapear.
        if (userResult.status === 'RECOVERY') {
          rowData.status = 'PW Reset Sent - Success'
        } else {
          rowData.status = `${userResult.status} - Success`
        }
        dispatch(thunks.clientUsers.SetUserStatus(rowData))
      }
    }
  }, [userResult])

  return (
    <tr>
      <td>
        <CheckboxField
          name={`clientTeamAssignments[${rowNumber}].isAssigned`}
        />
      </td>
      <td style={{ minWidth: 350 }}>
        {rowData.name}
        <br />
        <span>{rowData.email}</span>
      </td>
      <td style={{ minWidth: 200 }}>
        {formatDate(rowData.lastLogin as unknown as Date)}
      </td>
      <td style={{ minWidth: 200 }}>
        {userStatus === 'RECOVERY' ? 'PW Reset Sent' : userStatus}
      </td>
      <td>
        {!rsmIsFederated && (
          <UserAction
            userId={userId}
            type={userType}
            status={userStatus}
            onResendWelcome={(userId: string) => handleResendWelcome(userId)}
            onResetPassword={(userId: string) => handleResetPassword(userId)}
            onUnlock={(userId: string) => handleUnlock(userId)}
          />
        )}

        {rsmIsFederated && (
          <div style={{ textAlign: 'center', marginTop: '12px' }}>
            FEDERATED ACCOUNT
          </div>
        )}
      </td>
      {isOktaErrModalOpen && (
        <UserErrModal
          handleModalClose={handleCloseOktaModal}
          userErrs={GenericErrorMessage}
          titleErr={`Error ${lastAction}`}
        />
      )}
    </tr>
  )
}

type StyleReturn = keyof ReturnType<typeof styles>
const useStyles = createUseStyles<Theme, StyleReturn>(styles)

const ClientUserAssignment = () => {
  const classes = useStyles()
  const {
    errors,
    isSubmitting,
    setFieldValue,
    submitCount,
    values,
  }: FormikProps<FormModel> = useFormikContext()
  const clientTeamAssignments = values.clientTeamAssignments || []
  const originalAssignments = values.assignments || []
  const clientUsers = useSelector((state: AppState) => state.clientUsers.data)
  const cuCompleted = useSelector(
    (state: AppState) => state.clientUsers.completed
  )
  const clientIsLoading = useSelector(
    (state: AppState) => state.clientUsers.isLoading
  )
  const clientId = useSelector((state: AppState) => state.clientUsers.clientId)
  const { provisionUser, requestStatus, requestErrors } = useUsers()
  const [isUserErrModalOpen, setIsUserErrModalOpen] = useState(false)
  const [provisionUserEmail, setProvisionUserEmail] = useState('')
  const handleAddUser = async (user: UserProfileModel) => {
    const model: ProvisionUserRequestModel = {
      organizationId: clientId,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      claims: ['ClientUser'],
    }
    await provisionUser(model)
    setProvisionUserEmail(model.email)

  }
  // useEffect(()=>{
  //   // the result of soem of the support buttons.
  //   if(userResult){
  //     console.log(userResult.rsmUid)
  //   }
  // },[userResult]
  // )
  useEffect(() => {
    if (!isSubmitting && cuCompleted) {
      const localProvisionUserEmail = provisionUserEmail
      if (localProvisionUserEmail) // add tot he clientteam assigments
      {
        const user = clientUsers.find((x) => x.email === localProvisionUserEmail)
        // now create a clientteam assigment
        if(user){
        const clientAssignment: TeamAssignment ={
          isAssigned:true,
          firstName: user?.firstName,
          lastName: user?.lastName,
          userId: user.userId,
          email: user.email,
          type: 'External',
          status:user.status,
          roles:{ClientPreparer: true},
          rsmIsFederated: user.rsmIsFederated
        } 
        clientTeamAssignments.push(clientAssignment)
        }
      }
      const { clientTeamAssignments: updatedAssignments } =
        getMergedClientUserAssignments(
          originalAssignments,
          clientTeamAssignments,
          clientUsers
        )
      setFieldValue('clientTeamAssignments', updatedAssignments)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientUsers, originalAssignments, setFieldValue])
  useEffect(() => {
    if (requestStatus === 'Failed') {
      setIsUserErrModalOpen(true)
    }
  }, [requestStatus])
  // close the error modal
  const handleUserErrModalClose = () => {
    setIsUserErrModalOpen(false)
  }
  return (
    <div>
      <div className={classes.title}>Client User Assignments</div>
      {!!errors.clientTeamAssignments && (
        <div className={classes.errorMessage}>
          {errors.clientTeamAssignments}
        </div>
      )}
      <Loader ready={!clientIsLoading}>
        <div>
          <Grid container xs='5'>
            <div
              className={clsx(
                classes.tableWrapper,
                errors.clientTeamAssignments && submitCount > 0
                  ? classes.error
                  : classes.plain
              )}
            >
              <table className={classes.table}>
                <thead>
                  <tr>
                    <th>
                      <input
                        type='checkbox'
                        onChange={(e) => {
                          const newVal = clientTeamAssignments.map(
                            (user: TeamAssignment) => ({
                              ...user,
                              isAssigned: e.currentTarget.checked,
                            })
                          )
                          setFieldValue('clientTeamAssignments', newVal)
                        }}
                        checked={clientTeamAssignments.some(
                          (user: TeamAssignment) => !!user.isAssigned
                        )}
                      />
                    </th>
                    <th>Name/Email Address</th>
                    <th>Last Login</th>
                    <th>Status</th>
                    <th style={{ textAlign: 'center' }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {clientTeamAssignments.map(
                    (user: TeamAssignment, i: number) => {
                      return user.userId ? (
                        <UserRow
                          key={user.userId}
                          rowNumber={i}
                          rowData={user}
                        />
                      ) : null
                    }
                  )}
                  {!clientTeamAssignments.length && (
                    <tr>
                      <td colSpan={3}>&nbsp;</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Grid>
          {clientId && (
            <FindUserButton
              onAddUser={handleAddUser}
              internalExternal={'External'}
              clientId={clientId}
              userDialogAdditional={
                'Enter in the Email, First Name and or Last Name'
              }
              userDialogTitle={'Add a User to Client'}
            />
          )}
        </div>
      </Loader>
      {
        // this is an error modal
      }
      {isUserErrModalOpen && (
        <UserErrModal
          handleModalClose={handleUserErrModalClose}
          userErrs={requestErrors}
          titleErr={'Errors in Assign/Add User:'}
        />
      )}
    </div>
  )
}

export default ClientUserAssignment
