import { createStyles, Theme } from '@rsmus/react-ui'
import React from 'react'
import { createUseStyles } from 'react-jss'
import { useHistory } from 'react-router-dom'

const styles = ({ palette, typogrphaphy, transitions }: Theme) =>
  createStyles({
    addButton:{
        color:palette.blue.main,
        backgroundColor: palette.common.white,
        height:'2.375rem',
        marginLeft:'2rem',
        borderRadius:'2.375rem',
        padding:'0px 1rem',
        fontStyle:'normal',
        fontFamily:'Prelo-Bold',
        border:'none'
    }
   
  })

type StyleReturn = keyof ReturnType<typeof styles>
const useStyles = createUseStyles<Theme, StyleReturn>(styles)
const AddEngagementButton = () => {
  const history = useHistory()
  const onCLickAddNew = (event: any) => {
    history.push('/engagements/new')
  }
  const classes = useStyles()
  return (
    <button 
    type ='button'
    className={classes.addButton} 
    onClick={onCLickAddNew}>
      + Add New
    </button>
  )
}
export default AddEngagementButton