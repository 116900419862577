import React from 'react'
import {
  createStyles,
  Theme,
  // RoundDropdown
} from '@rsmus/react-ui'
import { createUseStyles } from 'react-jss'

const styles = ({ palette }: Theme) =>
  createStyles({
    engagementListHeader: {
      alignItems: 'center',
      display: 'flex',
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
      margin: '15px 0',
      '& h2': {
        paddingLeft: '20px',
      },
    },
    engagementListButtons: {
      alignItems: 'center',
      display: 'flex',
      flexWrap: 'wrap',
      marginRight: '10px',
      zIndex: 0,
    },
    clearFiltersButton: {
      backgroundColor: 'transparent',
      border: 'none',
      color: palette.blue.main,
      marginRight: '1.25rem',
      '&:focus': {
        color: palette.blue.dark,
        outline: 'none',
      },
      '&:hover': {
        color: palette.blue.dark,
      },
    },
    filterButton: {
      backgroundColor: palette.blue.dark,
      borderRadius: '0.3rem',
      color: palette.common.white,
      cursor: 'pointer',
      margin: '0 0.6rem',
      '& button': {
        backgroundColor: palette.blue.dark,
        border: 'none',
        borderRadius: '0',
        color: palette.common.white,
        fontWeight: 500,
        position: 'relative',
        '&:active, &:focus': {
          outline: 'none',
        },
      },
      '& div': {
        top: '2rem',
        width: '100%',
      },
    },
  })

type StyleReturn = keyof ReturnType<typeof styles>
const useStyles = createUseStyles<Theme, StyleReturn>(styles)

const ComponentContent = () => {
  const classes = useStyles()
  // const handleClearFilters = () => {}
  return (
    <div className={classes.engagementListHeader}>
      <h2>My Engagements</h2>
      {/* <div className={classes.engagementListButtons}>
        <button
          type='button'
          className={classes.clearFiltersButton}
          onClick={handleClearFilters}
        >
          Clear all filters
        </button>
        <RoundDropdown
          buttonText='ALL MY CLIENTS'
          className={classes.filterButton}
        ></RoundDropdown>
        <RoundDropdown
          buttonText='ALL TAX YEARS'
          className={classes.filterButton}
        ></RoundDropdown>
        <RoundDropdown
          buttonText='ALL ENGAGEMENT TYPES'
          className={classes.filterButton}
        ></RoundDropdown>
      </div> */}
    </div>
  )
}

export const EngagementListHeader = ComponentContent
