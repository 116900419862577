import React from 'react'
import { Button, createStyles, Theme } from '@rsmus/react-ui'
import { createUseStyles } from 'react-jss'
import { UserProfileModel } from '../../models'
import { useSelector } from 'react-redux'
import { AppState } from '../../redux'

const styles = ({ palette }: Theme) =>
  createStyles({
    userContainer: {
      display: 'flex',
      flexDirection: 'row',
      paddingLeft: '0.7rem',
      paddingTop: '0.3rem',
    },
    buttonContainer: {
      paddingRight: '0.5rem',
    },
    button: {
      paddingRight: '0.5rem',
      padding: '0.3rem 1 rem',
      fontSize: '13px',
    },
    infoContainer: {
      flex: 1,
      fontSize: '0.9rem',
      textAlign: 'left',
    },
    userName: {
      fontWeight: 'bold',
    },
    deactivated: {
      fontWeight: 'bold',
      color: palette.informational.red,
    },
    noAccess: {
      fontWeight: 'bold',
      color: palette.informational.red,
    },
  })

type StyleReturn = keyof ReturnType<typeof styles>
const useStyles = createUseStyles<Theme, StyleReturn>(styles)
interface RenderStatusOwnProps {
  status: string
}

const RenderStatus = ({ status }: RenderStatusOwnProps) => {
  const classes = useStyles()
  return (
    <div>
      {status === 'DEPROVISIONED' && (
        <span className={classes.deactivated}>Deactivated</span>
      )}
      {status !== 'DEPROVISIONED' && (
        <span className={classes.userName}>{status}</span>
      )}
    </div>
  )
}
export interface RenderFindUserOwnProps {
  user: UserProfileModel
  index: string
  disabled?: boolean
  onClick: (user: UserProfileModel, index: string) => void
}

const RenderFindUser = ({
  user,
  index,
  disabled,
  onClick,
}: RenderFindUserOwnProps) => {

  let clientAccessMessage = ''
  const userName = `${user.lastName}, ${user.firstName}`
  const classes = useStyles()

  const userInfo: any | undefined = useSelector(
    (state: AppState) => state.userInfo
  )
 
  if (userInfo !== null || userInfo !== undefined) {
    if(userInfo.name === user.rsmUid){
      clientAccessMessage='You must request access to the client before you can assign yourself to the engagement'
      disabled=true
    }
  }

  return (
    <div key={index}>
      <div className={classes.userContainer}>
        <div className={classes.infoContainer}>
          <div>
            <span className={classes.userName}>{userName}</span>
          </div>
          <div>
            <span>{user.email}</span>
          </div>
          <RenderStatus status={user.status} />
          <span className={classes.noAccess}>{clientAccessMessage}</span>
        </div>
        <div className={classes.buttonContainer}>
          <Button
            variant={'primary'}
            disabled={disabled}
            color='primary'
            onClick={() => onClick(user, index)}
          >
            ADD USER
          </Button>
        </div>
      </div>
      <hr />
    </div>
  )
}

export default RenderFindUser
