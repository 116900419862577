import * as React from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '../../redux'

type Permission = 'DeleteEngagements'

interface UserPermissionProps {
  permission: Permission
  children: React.ReactNode
}

const HasPermission = ({ children, permission }: UserPermissionProps) => {
  const has = useSelector((state: AppState) => {
    var permissionState = state.userPermissions
    if (permissionState && !permissionState.enabled) {
      return true
    } else if (
      permissionState &&
      permissionState.permissions &&
      permissionState.permissions.includes(permission)
    ) {
      return true
    }
    return false
  })
  return <React.Fragment>{has ? children : null}</React.Fragment>
}

export default HasPermission
