import {
  PingIdentity,
  Authenticated,
  getAccessToken,
  Eula,
} from '@rsmus/react-pingidentity'
import { CssBaseline } from '@rsmus/react-ui'
import React, { Fragment } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { Authorized } from './components/Authorized'
import store from './redux/store'
import { 
        REACT_APP_IDMAUTHENTICATIONCLIENTID,
        REACT_APP_OIDCSILENTREFRESHOFFSETTIMEINSECONDS,
        REACT_APP_IDMAUTHENTICATIONAUTHORITY,
        REACT_APP_AUTHORIZATIONSERVICEURL,
        REACT_APP_IDMAUTHENTICATIONEULASERVICEURL,
        REACT_APP_DEVEXTREMELICENSE
        } from './envVariables'
import AccountMenu from './components/AccountMenu/AccountMenu'
// import {licenseKey} from './devextreme-license'
import config from 'devextreme/core/config'

config({
  licenseKey:REACT_APP_DEVEXTREMELICENSE
})

export type SupportedApplication =
  | 'TSA Hub'
  | 'Engagement Manager'
  
const silentRefreshUri = window.location.origin + '/silent-refresh.html'

/**
 * Engagement Manager App Component
 */
const App: React.FC = () => {
  const getToken = async (): Promise<string> => {
    const token = await getAccessToken()
    return token || ''
  }

  return (
    <Fragment>
      <CssBaseline />
      <Provider store={store}>
        <Router>
          <PingIdentity
            authority={REACT_APP_IDMAUTHENTICATIONAUTHORITY}
            clientId={REACT_APP_IDMAUTHENTICATIONCLIENTID}
            loginRedirectUri={window.location.origin} // was .href
            silentRefreshUri={silentRefreshUri}
            silentRefreshOffsetSeconds={REACT_APP_OIDCSILENTREFRESHOFFSETTIMEINSECONDS}
            autoLogin={true}
            claimsAuthority={REACT_APP_AUTHORIZATIONSERVICEURL}
          >
            <Authenticated>
              <Eula
                host={REACT_APP_IDMAUTHENTICATIONEULASERVICEURL}
                tokenFactory={getToken}
              >
                <Authorized>
                  <AccountMenu/>
                </Authorized>
              </Eula>
            </Authenticated>
          </PingIdentity>
        </Router>
      </Provider>
    </Fragment>
  )
}

export default App
