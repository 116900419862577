import ky from '@rsmus/ky-auth'
import { Actions } from './actions'
import { ClientUser } from '../models'
import { AppThunk } from './store'
import { UserPermissionsState } from './reducers'
import { buildApiUrl } from '../apiUrl'

export const getUser = (): AppThunk => async dispatch => {
  await dispatch(getUserInfo())
  await dispatch(getUserPermissions())
}

export const getUserInfo = (): AppThunk => async dispatch => {
  try {
    const url = buildApiUrl('userprofiles/GetUserInfo')
    const response = await ky.get(url, {
      timeout: 300000,
    })
    const userInfo: any = await response.json()
    dispatch(Actions.getUserInfo(userInfo))
  } catch (error) {
    dispatch(Actions.getUserManagementError(error))
  }
}

export const getUserPermissions = (): AppThunk => async dispatch => {
  try {
    dispatch(Actions.getUserPermissionsBegin())
    const url = buildApiUrl('userPermissions')
    const response = await ky.get(url)
    const userPermissions: UserPermissionsState = await response.json()
    dispatch(Actions.getUserPermissionsEnd(userPermissions))
  } catch (error) {
    dispatch(Actions.getUserPermissionsError(error))
  }
}

export const getAllUserProfiles = (): AppThunk => async dispatch => {
  try {
    dispatch(Actions.getClientUsersProfilesBegin())

    const url = buildApiUrl('userprofiles/GetAllUserProfiles')
    const response = await ky.get(url, {
      timeout: 300000,
    })
    const users: ClientUser[] = await response.json()

    dispatch(Actions.getClientUsersProfilesEnd(users))
  } catch (error) {
    dispatch(Actions.getClientUsersProfilesError(error))
  }
}
