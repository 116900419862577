import { AppThunk } from './store'
import { Actions } from './actions'
import ky from '@rsmus/ky-auth'
import { ClientSearchResult } from '../models'
import { buildApiUrl } from '../apiUrl'

let controller = new AbortController()

// unused?
export const searchClients = (search: string): AppThunk => async dispatch => {
  // Abort an in-flight request if there is one
  controller.abort()

  try {
    const { signal } = controller
    dispatch(Actions.clientSearchBegin())
    const url = buildApiUrl(`/clientSearch?search=${search}&allClients=true&max=100`)
    const results = await ky
      .get(url, {
        signal,
      })
      .json<ClientSearchResult[]>()
    dispatch(Actions.clientSearchEnd(results))
  } catch (error) {
    dispatch(Actions.clientSearchError(error))
  }
}
