import { AppThunk } from './store'
import { Actions } from './actions'
import ky from '@rsmus/ky-auth'
import {
  ClientUserEngagements,
  ClientUserAssignmentUpdateRequest,
} from '../models'
import { buildApiUrl } from '../apiUrl'

let controller = new AbortController()

const inactivePhases: string[] = ['cch', 'irs', 'closed']
export const isInactivePhase = (phase: string) => inactivePhases.includes(phase)

export const getClientUserEngagements =
  (id: string): AppThunk =>
  async (dispatch) => {
    controller.abort()

    try {
      const { signal } = controller
      dispatch(Actions.getClientUserEngagementsBegin())

      const url = buildApiUrl(`userprofiles/${id}/clientengagements`)
      const results = await ky
        .get(url, {
          signal,
          timeout: 300000,
        })
        .json<ClientUserEngagements>()

      // Add a status field to engagements for filtering purposes
      results.clientEngagements = results.clientEngagements.map((ce) => {
        ce.engagements = ce.engagements.map((e) => ({
          ...e,
          status: isInactivePhase(e.phase) ? 'Closed' : 'Active',
        }))
        return ce
      })

      dispatch(Actions.getClientUserEngagementsEnd(results))
    } catch (error) {
      dispatch(Actions.getClientUserEngagementsError(error))
    }
  }

export const saveClientUserAssignment =
  (request: ClientUserAssignmentUpdateRequest, history: any): AppThunk =>
  async (dispatch) => {
    controller.abort()

    try {
      const { signal } = controller
      dispatch(Actions.postClientUserAssigmentsBegin(request))
      const url = buildApiUrl('clientAssignments')
      const response = await ky.post(url, {
        json: request,
        signal,
        throwHttpErrors: false,
      })

      if (response.ok) {
        dispatch(Actions.postClientUserAssigmentsEnd({}))
        history.push('/users')
      } else {
        const errorMessage = await response.text()
        dispatch(Actions.postClientUserAssigmentsError(new Error(errorMessage)))
      }
    } catch (error) {
      dispatch(Actions.postClientUserAssigmentsError(error))
    }
  }
