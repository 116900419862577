import react, { useCallback, useState } from 'react'
import { ClientAccessRequest, Dictionary } from '../models'
import ky from '@rsmus/ky-auth'
import { buildApiUrl } from '../apiUrl'

export const useClientRequests = () => {
  const [requestStatus, setRequestStatus] = useState<string>('Idle')
  const [requestGetStatus, setRequestGetStatus] = useState<string>('Idle')
  const [clientRequestResults, setClientRequestResults] = useState<
    ClientAccessRequest[]
  >([])
  const [isClientAccessApprover, setIsClientAccessApprover] =
    useState<boolean>(false)
  const [requestErrors, setRequestErrors] = useState<Dictionary<string>>({})
  // const [userResult, setUserResults] = useState<UserProfileModel>()
  // const controller = new AbortController()
  // const dispatch = useDispatch<AppDispatch>()
  const getAllClientRequests = useCallback(
    async () => {
      const url = buildApiUrl('ClientAccessRequest/allAccessRequest')
      try {
        setRequestGetStatus('Processing')
        const results = await ky
          .get(url, {
            timeout: 300000,
          })
          .json<ClientAccessRequest[]>()
        setClientRequestResults(results)
        setRequestGetStatus('Success')
      } catch (error) {
        setRequestGetStatus('Failed')
      }
    },
    // eslint-disable-next-line
    []
  )
  const approveClientRequest = useCallback(
    async (id: number) => {
      const queryString = {
        Id: id + '', // force a string
      }

      const url = buildApiUrl(
        'ClientAccessRequest/approveAccessRequest',
        queryString
      )
      try {
        setRequestStatus('Processing')
        const response = await ky.post(url, {
          timeout: 300000,
          throwHttpErrors: false,
        })
        if (response.ok) {
          const result: ClientAccessRequest = await response.json()
          //setClientRequestResults(results)
          // we need to update results base don hte id in the results.
          const tempresults = [...clientRequestResults]
          const row = tempresults.findIndex((x) => x.id === result.id)
          tempresults[row] = { ...result }
          setClientRequestResults(tempresults)

          setRequestStatus('Success')
        } else {
          const errorMessage = await response.text()
          setRequestErrors({ err: errorMessage })
          setRequestStatus('Failed')
        }
      } catch (error) {
        const expErr = error as Error
        setRequestErrors({ err: expErr.message })
        setRequestStatus('Failed')
      }
    },
    // eslint-disable-next-line
    [clientRequestResults]
  )
  const hasApproverAccess = useCallback(
    async () => {
      const url = buildApiUrl('ClientAccessRequest/findUserIsApprover')
      try {
        setRequestGetStatus('Processing')
        const results = await ky
          .get(url, {
            timeout: 300000,
          })
          .json<boolean>()
        setIsClientAccessApprover(results)
        setRequestGetStatus('Success')
      } catch (error) {
        setRequestGetStatus('Failed')
      }
    },
    // eslint-disable-next-line
    []
  )
  return {
    requestStatus,
    requestGetStatus,
    requestErrors,
    clientRequestResults,
    isClientAccessApprover,
    hasApproverAccess,
    getAllClientRequests,
    approveClientRequest,
  }
}
