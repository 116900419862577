import React from 'react'
import { createStyles, Button, Theme } from '@rsmus/react-ui'
import { Modal } from './Modal'
import { createUseStyles } from 'react-jss'

const styles = () =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
    },
    button: {
      display: 'inline-block',
      width: 'auto',
      margin: '1rem',
      minWidth: '4rem',
    },
    row: {
      margin: '0 auto',
      display: 'flex',
      justifyContent: 'center',
      justifyItems: 'center',
    },
  })

export interface ConfirmationButton {
  label: string
  variant: 'standard' | 'primary' | 'secondary'
  action: () => void
}

export interface ConfirmationAlertProps {
  children: React.ReactNode
  shown: boolean
  buttons: ConfirmationButton[]
  onDismiss?: () => void
}

type StyleReturn = keyof ReturnType<typeof styles>
const useStyles = createUseStyles<Theme, StyleReturn>(styles)

const ComponentContent = ({
  children,
  shown,
  buttons,
  onDismiss,
}: ConfirmationAlertProps) => {
  const classes = useStyles()

  const handleDismiss = () => {
    if (onDismiss) {
      onDismiss()
    }
  }

  const items = buttons.map(b => {
    return (
      <Button
        key={b.label}
        variant={b.variant}
        onClick={b.action}
        className={classes.button}
      >
        {b.label}
      </Button>
    )
  })

  return (
    <div className={classes.container}>
      <Modal shown={shown} onClose={handleDismiss}>
        {children}
        <div className={classes.row}>{items}</div>
      </Modal>
    </div>
  )
}

export const ConfirmationAlert = ComponentContent
