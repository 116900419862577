import moment, { unitOfTime } from 'moment'
import { CountryTypes } from './options'

export function cleanClientModel(model: any) {
  var result = { ...model }
  for (var prop in result) {
    if (result[prop] === '') {
      delete result[prop]
    }
  }
  return result
}

export const convertCountryCodeToName = (code: string | undefined) => {
  const country = CountryTypes.find((t) => t.value === code)
  return country ? country.label : ''
}

const presentationDateFormat = 'MM/DD/YYYY'
export function convertDateToIsoFormat(date?: string | null) {
  if (!date) {
    return undefined
  }
  var momentDate = moment.utc(date, presentationDateFormat, true)

  if (!momentDate.isValid()) {
    return undefined
  }
  return momentDate.toISOString()
}

export function convertDateToPresentationFormat(date?: string | null) {
  if (!date) {
    return undefined
  }
  var momentDate = moment.utc(date)
  if (!momentDate.isValid()) {
    return undefined
  }
  var localDate = momentDate.toDate()
  return moment(localDate).format('MM/DD/YYYY hh:mm:ss A')
}
export function dateDifference(
  timeQualifier: unitOfTime.Diff,
  startDate?: string,
  endDate?: string
) {
  var startMomentDate = moment.utc(startDate)
  if (!startMomentDate.isValid()) {
    return undefined
  }
  var endMomentDate = moment.utc(endDate)
  if (!endMomentDate.isValid()) {
    return undefined
  }
  // both are valid dates. now get diff in hours
  var results = endMomentDate.diff(startMomentDate, timeQualifier,true)
  //return Math.round(results * 100) / 100
  return `${Math.round((results +Number.EPSILON) * 100) / 100} ${timeQualifier}`
}
