import React, { useState } from 'react'
import clsx from 'clsx'
import { createStyles, Modal, Theme, Button } from '@rsmus/react-ui'
import { createUseStyles } from 'react-jss'
import { FaExclamationCircle } from 'react-icons/fa'

const styles = ({ palette }: Theme) =>
  createStyles({
    button: {
      zIndex: 1,
      padding: '0.5rem',
      minWidth: '8rem',
      border: 0,
      borderRadius: '0.25rem',
      marginRight: '1rem',
      marginLeft: '1rem',
    },
    '&:focus': {
      // TODO: Set a nicer style for focus outline
      outline: 'none',
    },
    buttonPrimary: {
      color: palette.common.white,
      backgroundColor: palette.blue.main,
      '&:disabled': {
        opacity: 0.4,
      },
    },
    modalText: {
      fontSize: '1.25rem',
    },
    modalButton: {
      margin: '2rem',
      marginBottom: '.1rem',
      width: '6rem',
      display: 'inline-flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'center',
    },
    resultIcon: {
      fontSize: '4rem',
      marginBottom: '12px',
      color: palette.informational.yellow,
    },
    modalTitle: {
      fontSize: '1.8rem',
      lineHeight: '2rem',
      letterSpacing: '0.075rem',
      marginBottom: '1rem',
      maxWidth: '100%', // fix IE11 flexbox issue that allowed div to overflow the parent div
    },
  })

export type ParentOu = 'Unknown' | 'External' | 'Internal'

export interface UserActionProps {
  userId?: string
  status: string
  type: ParentOu
  onResetPassword: (userId: string) => void
  onResendWelcome: (userId: string) => void
  onUnlock: (userId: string) => void
}

type StyleReturn = keyof ReturnType<typeof styles>
const useStyles = createUseStyles<Theme, StyleReturn>(styles)

export const UserAction = ({
  userId,
  status,
  onResetPassword,
  onResendWelcome,
  onUnlock,
  type,
}: UserActionProps) => {
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const classes = useStyles()
  const primaryButtonClassName = clsx(classes.button, classes.buttonPrimary)
    
  const onActionClickHandle = () => {
    setConfirmDialogOpen(true)
  }

  const onCancelClickHandle = () => {
    setConfirmDialogOpen(false)
  }
  
  const onYesClickHandle = () => {
    setConfirmDialogOpen(false)
    switch (status) {
      case 'PROVISIONED': {
        onResendWelcome(userId + '')
        break
      }
      case 'ACTIVE':
      case 'RECOVERY':
      case 'PASSWORD_EXPIRED': {
        onResetPassword(userId + '')
        break
      }
      case 'LOCKED_OUT': {
        onUnlock(userId + '')
      }
    }
  }

  const buttonToolTip = (status: string): string => {
    switch (status) {
      case 'PROVISIONED': {
        return 'The click action will  resend the IDP welcome Email to the user.'
      }
      case 'ACTIVE':
      case 'RECOVERY':
      case 'PASSWORD_EXPIRED': {
        return 'The click action will Send reset password email to the user.'
      }
      case 'LOCKED_OUT': {
        return 'The click action will UnLock the user.'
      }
      default: {
        return ''
      }
    }
  }

  const buttonCaption = (): string => {
    if (type === 'External') {
      switch (status) {
        case 'PROVISIONED': {
          return 'RESEND THE ACTIVATION EMAIL'
        }
        case 'ACTIVE':
        case 'RECOVERY':
        case 'PASSWORD_EXPIRED': {
          return 'RESET PASSWORD EMAIL'
        }
        case 'LOCKED_OUT': {
          return 'UNLOCK ACCOUNT'
        }
        default: {
          return '' // no button
        }
      }
    }
    return '' // no button
  }

  const confirmDialogTitle = (): string => {
    switch (status) {
      case 'PROVISIONED': {
        return 'Are you sure you want to proceed with resending the activation email.'
      }
      case 'ACTIVE':
      case 'RECOVERY':
      case 'PASSWORD_EXPIRED': {
        return 'Are you sure you want to proceed with sending a reset password email.'
      }
      case 'LOCKED_OUT': {
        return 'Are you sure you want to proceed with unlocking this account.'
      }
      default: {
        return '' // no dialog caption
      }
    }
  }
  
  if (buttonCaption() !== '') {
    return (
      <div>
        <React.Fragment>
          <button
            style={{
              minWidth: 250, // keeps buttons same size
            }}
            className={primaryButtonClassName}
            title={buttonToolTip(status)}
            onClick={onActionClickHandle}
            type='button'
          >
            {buttonCaption()}
          </button>
          {confirmDialogOpen && (
            <Modal shown={true} onClose={onCancelClickHandle}>
            <div>
              <FaExclamationCircle className={classes.resultIcon} />
              <div className={classes.modalTitle}>Confirm Action</div>
              <div className={classes.modalText}>
                {confirmDialogTitle()}
              </div>
              <Button
                className={classes.modalButton}
                onClick={onCancelClickHandle}
              >
                CANCEL
              </Button>
              <Button
                className={`${classes.modalButton} ${classes.buttonPrimary}`}
                onClick={onYesClickHandle}
              >
                YES
              </Button>
              
            </div>
          </Modal>
          )}
        </React.Fragment>
      </div>
    )
  } else {
    return null
  }
}
