import React from 'react'
import EngagementListScene from '../EngagementListScene/EngagementListScene'
import EngagementScene, {
  NewEngagementScene,
} from '../EngagementScene/EngagementScene'
import { IE11Banner } from '@rsmus/react-ui'
import { useSelector } from 'react-redux'
import { AppState } from '../../redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import { setDeepLink, getInitialPath } from './AppContentHelpers'
import AccessRequestListScene from '../AccessRequestListScene/AccessRequestListScene'

const AppContent = () => {
  const userInfo: any | undefined = useSelector(
    (state: AppState) => state.userInfo
  )

  if (!userInfo) {
    return null
  }
  setDeepLink()

  return (
    <React.Fragment>
      <IE11Banner />
      <Switch>
        <Route path='/engagements/new/:step?' component={NewEngagementScene} />
        <Route
          path='/engagements/:id/rollforward/:step?'
          component={NewEngagementScene}
        />
        <Route path='/engagements/:id/:step?' component={EngagementScene} />
        <Route path='/engagements' component={EngagementListScene} />
        <Route path='/accessrequests/:Id' component={AccessRequestListScene} /> {/*  we set the filter based on this.*/ }
        <Route path='/accessrequests' component={AccessRequestListScene} />
        <Route path='/'>
          <Redirect to={getInitialPath()} />
        </Route>
      </Switch>
    </React.Fragment>
  )
}

export default AppContent
