import { createStyles, Theme, Button } from '@rsmus/react-ui'
import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { Engagement, Index } from '../../models'
import { AppDispatch, AppState, thunks } from '../../redux'
import { EngagementListHeader } from './EngagementListHeader'
import { TableBody } from './TableBody'
import { TableHeader } from './TableHeader'
import { Modal } from '../Shared/Modal'
import { FaExclamationCircle } from 'react-icons/fa'

const styles = ({ palette }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    loading: {
      fontSize: '1.2rem',
      margin: '15px 0',
      textAlign: 'center',
    },
    noEngagements: {
      fontSize: '1.2rem',
      marginTop: 40,
      padding: '10px 0 10px 20px',
    },
    engagementListScreen: {
      position: 'absolute',
      width: '100%',
    },
    engagementListTable: {
      width: '100%',
      '& tr td': {
        padding: 10,
        verticalAlign: 'top',
      },
      '& tr td:first-child': {
        paddingLeft: 20,
      },
      '& tr td:last-child': {
        paddingRight: 20,
      },
      '& tr:nth-child(even) td': {
        backgroundColor: palette.grey[100],
      },
      '& tr:hover td': {
        backgroundColor: palette.grey[200],
      },
    },
    engagementListTableHeader: {
      backgroundColor: palette.grey[300],
      '& tr th': {
        padding: 10,
        verticalAlign: 'top',
      },
      '& tr th:first-child': {
        paddingLeft: 20,
      },
      '& tr th.text-center': {
        textAlign: 'center',
      },
    },
    button: {
      zIndex: 1,
      padding: '0.5rem',
      minWidth: '8rem',
      border: 0,
      borderRadius: '0.25rem',
      marginRight: '1rem',
      textAlign: 'center',
    },
    '&:focus': {
      // TODO: Set a nicer style for focus outline
      outline: 'none',
    },
    buttonPrimary: {
      color: palette.common.white,
      backgroundColor: palette.blue.main,
      '&:disabled': {
        opacity: 0.4,
      },
    },
    modalText: {
      fontSize: '1.25rem',
    },
    modalButton: {
      margin: '2rem',
      marginBottom: '.1rem',
      width: '6rem',
      display: 'inline-flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'center',
    },
    resultIcon: {
      fontSize: '4rem',
      marginBottom: '12px',
      color: palette.informational.yellow,
    },
    modalTitle: {
      fontSize: '1.8rem',
      lineHeight: '2rem',
      letterSpacing: '0.075rem',
      marginBottom: '1rem',
      maxWidth: '100%', // fix IE11 flexbox issue that allowed div to overflow the parent div
    },
  })

type RouteProps = RouteComponentProps<{}>

interface EngagementListOwnProps extends RouteProps {}

type StyleReturn = keyof ReturnType<typeof styles>
const useStyles = createUseStyles<Theme, StyleReturn>(styles)

export type EngagementListProps = EngagementListOwnProps

const EngagementListScene = () => {
  const classes = useStyles()
  const dispatch = useDispatch<AppDispatch>()
  const engagements: Index<Engagement> = useSelector(
    (state: AppState) => state.engagements.data
  )
  const isLoading: Boolean = useSelector(
    (state: AppState) => state.engagements.isLoading
  )
  const [deleteModalShown, setDeleteModalShown] = useState(false)
  const [deleteEngagementId, setDeleteEngagementId] = useState<string | number>(
    ''
  )

  useEffect(() => {
    dispatch(thunks.engagement.getAllEngagements())
  }, [dispatch])

  const toggleFavoriteEngagement = (engagementId: number | string) => {
    dispatch(thunks.engagement.toggleFavorite(engagementId))
  }

  const showSoftDeleteModal = (engagementId: number | string) => {
    setDeleteModalShown(true)
    setDeleteEngagementId(engagementId)
  }

  const closeSoftDeleteModal = () => {
    setDeleteModalShown(false)
    setDeleteEngagementId('')
  }

  const softDeleteEngagement = () => {
    dispatch(thunks.engagement.softDeleteEngagement(deleteEngagementId))
    closeSoftDeleteModal()
  }

  const sortEngagements = (a?: Engagement, b?: Engagement) => {
    // undefined check
    if (!a || !b) {
      return 0
    }
    if (
      a.client &&
      b.client &&
      a.client.name &&
      b.client.name &&
      a.client.name < b.client.name
    ) {
      return -1
    }
    if (
      a.client &&
      b.client &&
      a.client.name &&
      b.client.name &&
      a.client.name > b.client.name
    ) {
      return 1
    }
    if (a.name < b.name) {
      return -1
    }
    if (a.name > b.name) {
      return 1
    }
    if (a.taxYear < b.taxYear) {
      return 1
    }
    if (a.taxYear > b.taxYear) {
      return -1
    }
    return 0
  }

  let sortedEngagements: Array<Engagement | undefined> = []
  if (Object.values(engagements).length) {
    sortedEngagements = Object.values(engagements).sort(sortEngagements)
  }

  return (
    <div className={classes.engagementListScreen}>
      {isLoading ? (
        <p className={classes.loading}>Loading...</p>
      ) : (
        <>
          <EngagementListHeader />
          <table className={classes.engagementListTable}>
            <thead className={classes.engagementListTableHeader}>
              <TableHeader />
            </thead>
            <tbody>
              <TableBody
                engagements={sortedEngagements}
                toggleFavorite={toggleFavoriteEngagement}
                showSoftDeleteModal={showSoftDeleteModal}
              />
            </tbody>
          </table>
          <Modal shown={deleteModalShown} onClose={closeSoftDeleteModal}>
            <div>
              <FaExclamationCircle className={classes.resultIcon} />
              <div className={classes.modalTitle}>Delete Engagement</div>
              <div className={classes.modalText}>
                All engagement data and history will be deleted. This cannot be
                undone once completed. Would you still like to proceed?
              </div>
              <Button
                className={classes.modalButton}
                onClick={closeSoftDeleteModal}
              >
                CANCEL
              </Button>
              <Button
                className={`${classes.modalButton} ${classes.buttonPrimary}`}
                onClick={softDeleteEngagement}
              >
                YES
              </Button>
            </div>
          </Modal>
        </>
      )}
    </div>
  )
}

export default EngagementListScene
